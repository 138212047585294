import { combineReducers, Reducer } from 'redux';
import local, { State as localState, moduleName as localModule } from './ducks/local';

export interface RootState {
  [localModule]: localState;
}

export default function AppReducer(): Reducer {
  return combineReducers({
    [localModule]: local,
  });
}
