import { Space, Typography } from 'antd';
import ReferralsTable from './Table';
import ContentCard from '../../Common/ContentCard';

const RecentReferrals = () => (
  <ContentCard>
    <Space direction="vertical" size={24}>
      <Space size={4}>
        <Typography.Title>Referrals</Typography.Title>
      </Space>
      <ReferralsTable />
    </Space>
  </ContentCard>
);

export default RecentReferrals;
