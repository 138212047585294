import { Col, Form as AntdForm, Input, Row } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import React, { Dispatch, SetStateAction } from 'react';
import { useContextUsers } from '../../../../../context/users';
import { password } from '../../../../../utils/inputRules';
import Section from '../Section';

interface IPassword {
  editModeState: [string | null, Dispatch<SetStateAction<string | null>>];
}
const Password = ({ editModeState }: IPassword) => {
  const sectionName = 'password';
  const form = useFormInstance();
  const [editMode, setEditMode] = editModeState;

  const { profileUpdateLoading } = useContextUsers();

  const disabled = editMode !== sectionName;

  const handleEditModeChange = (isActive: boolean) => {
    setEditMode(isActive ? sectionName : null);
  };

  return (
    <Section
      form={form}
      label="Password"
      description="Please enter your current password to change your password."
      editable
      saveButtonLabel="Update Password"
      editButtonLabel="Change password"
      isEditModeActive={editMode === sectionName}
      handleEditModeChange={handleEditModeChange}
      controlsDisabled={!!editMode && editMode !== sectionName}
      controlsLoading={profileUpdateLoading}
    >
      <Row gutter={20}>
        <Col span={24}>
          <AntdForm.Item name="password" label="Current Password" rules={!disabled ? password : undefined} hasFeedback>
            <Input.Password
              size="large"
              placeholder="Enter Current Password"
              disabled={disabled}
              visibilityToggle={!disabled}
            />
          </AntdForm.Item>
        </Col>
        <Col span={24}>
          <AntdForm.Item name="newPassword" label="New Password" rules={!disabled ? password : undefined} hasFeedback>
            <Input.Password size="large" placeholder="New Password" disabled={disabled} visibilityToggle={!disabled} />
          </AntdForm.Item>
        </Col>
        <Col span={24}>
          <AntdForm.Item
            name="newPasswordConfirm"
            label="New Password Confirm"
            hasFeedback
            dependencies={['newPassword']}
            rules={
              !disabled
                ? [
                    ...password,
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('newPassword') === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(new Error('The new password you entered does not match.'));
                      },
                    }),
                  ]
                : undefined
            }
          >
            <Input.Password
              size="large"
              placeholder="Enter New Password Confirm"
              disabled={disabled}
              visibilityToggle={!disabled}
            />
          </AntdForm.Item>
        </Col>
      </Row>
    </Section>
  );
};

export default Password;
