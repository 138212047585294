import { Button, Divider, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { INotificationItem } from '../../../../hooks/notifications';
import { formatDateDayDate } from '../../../../utils/date';
import ContentCard from '../../../Common/ContentCard';
import Type from './Type';

interface INotificationRow {
  item: INotificationItem;
}

const NotificationRow = ({ item }: INotificationRow) => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    if (!item.link) return;

    navigate(item.link);
  };

  const getLinkText = () => {
    if (item.type === 'activity' && item.referral) return 'referral';

    return item.type;
  };

  return (
    <ContentCard bordered padding={20}>
      <Space direction="vertical" size={8}>
        <div className="flex-row flex-justify-space-between flex-align-start">
          <Space direction="vertical" size={8}>
            <Typography.Title level={4}>{item.title}</Typography.Title>
            <Typography.Text className="color-gray fs-075">{formatDateDayDate(item.createdAt)}</Typography.Text>
            {!!item.referral && <Typography.Text className="color-gray">From {item.title}</Typography.Text>}
          </Space>
          <Type type={item.type} />
        </div>
        <Typography.Text>{item.description}</Typography.Text>
        {!!item.link && (
          <>
            <Divider style={{ margin: 0 }} />
            <Button type="default" onClick={handleRedirect} className="uppercase color-gray">
              Open {getLinkText()}
            </Button>
          </>
        )}
      </Space>
    </ContentCard>
  );
};

export default NotificationRow;
