import { Button } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentMarketRates } from '../../../../hooks/reports';
import ContentCard from '../../../Common/ContentCard';
import MarketRatesChart from '../../../Common/MarketRatesChart';

const MarketRates = () => {
  const navigate = useNavigate();
  const currentMarketRates = useCurrentMarketRates();
  let interval: ReturnType<typeof setTimeout>;

  const getChartData = () => {
    currentMarketRates.fetch({ years: 30 });
  };

  useEffect(() => {
    getChartData();
    interval = setInterval(() => {
      getChartData();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleViewAll = () => {
    navigate('/market-rates');
  };

  return (
    <ContentCard height="100%">
      <MarketRatesChart
        homePage
        title="UMBS 30 Year - 5.0% Coupon"
        data={currentMarketRates.data?.data}
        loading={currentMarketRates.loading}
        height={250}
        controls={
          <Button type="text" className="color-gray uppercase" onClick={handleViewAll}>
            View All
          </Button>
        }
      />
    </ContentCard>
  );
};

export default MarketRates;
