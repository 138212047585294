import { Typography } from 'antd';
import Stripe from 'stripe';
import { capitalize } from '../../../utils/text';
import styles from './index.module.less';

interface IPaymentItem {
  card?: Stripe.PaymentMethod.Card;
}
const PaymentItem = ({ card }: IPaymentItem) => (
  <div className={styles.row}>
    {card && (
      <>
        {card.brand !== 'unknown' && card.brand !== 'unionpay' && (
          <div className={styles.image}>
            <img src={`/cards/${card.brand}.png`} alt={card.brand} />
          </div>
        )}
        <div className={styles.textBox}>
          <Typography className={styles.name}>
            {capitalize(card.brand)} ending in {card.last4}
          </Typography>
          <Typography className="color-gray">
            Expiry {card.exp_month}/{card.exp_year}
          </Typography>
        </div>
      </>
    )}
  </div>
);

PaymentItem.defaultProps = {
  card: undefined,
};
export default PaymentItem;
