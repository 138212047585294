import { Card, Divider } from 'antd';
import React, { CSSProperties } from 'react';

interface IContentCard extends CSSProperties {
  loading?: boolean;
  children: React.ReactNode;
  bordered?: boolean;
  footer?: React.ReactNode;
  header?: React.ReactNode;
}
const ContentCard = ({ loading, children, bordered, footer, header, ...styles }: IContentCard) => (
  <Card style={styles} bordered={bordered} loading={loading}>
    {header && (
      <>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>{header}</div>
        <Divider />
      </>
    )}
    {children}
    {footer && (
      <>
        <Divider />
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>{footer}</div>
      </>
    )}
  </Card>
);

ContentCard.defaultProps = {
  loading: false,
  bordered: false,
  padding: 0,
  height: 'fit-content',
  footer: undefined,
  header: undefined,
};

export default ContentCard;
