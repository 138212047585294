/* eslint-disable react/no-danger */
import moment from 'moment';
import { useState, useEffect } from 'react';
import { Typography, Tooltip } from 'antd';
import { Dot } from '../../../Common/Icon';
import { INews } from '../../../../hooks/news';
import './card.less';

interface NewsCardProps {
  news: INews;
  handleClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const images = [
  '/news/shutterstock_1939186822.jpg',
  '/news/shutterstock_2017272746.jpg',
  '/news/shutterstock_2096539801.jpg',
  '/news/shutterstock_2131907029.jpg',
];

const NewsCard: React.FC<NewsCardProps> = ({ news, handleClick }) => {
  const [randomImage, setRandomImage] = useState<string>('');

  useEffect(() => {
    setRandomImage(images[Math.floor(Math.random() * images.length)]);
  }, []);

  const text = (
    <div className="tooltipContainer">
      <Typography.Text className="textHeaderTool">Summary of Article</Typography.Text>
      <Typography.Text className="textBodyTool">
        <div dangerouslySetInnerHTML={{ __html: news.body }} />
      </Typography.Text>
    </div>
  );

  return (
    <div className="newsCard" onClick={handleClick}>
      <Tooltip
        title={text}
        placement="rightBottom"
        overlayInnerStyle={{ backgroundColor: 'var(--color-border)', width: '300px', overflow: 'hidden' }}
      >
        <div className="imageContainer">
          <img className="imageStyle" src={news.imgUrl || randomImage} alt="News" loading="lazy" />
        </div>
        <div className="textContainer">
          <Typography.Text className="categoryText">
            By{' '}
            <a href={news.link} target="_blank" rel="noreferrer">
              {news.origin}
            </a>{' '}
            - {news.category}
          </Typography.Text>
          <Typography.Text className="headerText">{news.header}</Typography.Text>
          <Typography.Paragraph className="textBody">
            <div dangerouslySetInnerHTML={{ __html: news.body }} />
          </Typography.Paragraph>
          <div className="dateAndLinkWrapper">
            <div className="dateAndLink">
              <Typography.Text className="date">
                {moment(news.pubDate).format('MMM DD, YYYY').toUpperCase()}
              </Typography.Text>
              <Dot />
              <Typography.Text className="link">
                Click{' '}
                <a href={news.link} target="_blank" rel="noreferrer">
                  here
                </a>{' '}
                to read full article
              </Typography.Text>
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default NewsCard;
