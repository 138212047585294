import { FetchResponse } from '../types';
import { DefaultFetchError, FetchCreate, FetchGet, useFetchCreate, useFetchGet } from './fetch';

export interface ICategory {
  id: number;
  name: string;
  updatedAt: string;
  createdAt: string;
}

interface ICategoryParams {
  name: string;
}

export const useCategories = (): FetchGet<FetchResponse<ICategory[]>> =>
  useFetchGet('categories', { autoStart: false, startStateLoading: false, multiple: 'categories' });

export const useCategoryCreate = (): FetchCreate<FetchResponse<ICategory>, DefaultFetchError, ICategoryParams> =>
  useFetchCreate('categories');
