import moment from 'moment-timezone';

export const getTimeAgo = (dateStr: string) => {
  const currentDate = moment();
  const date = moment(dateStr);

  const diffMinutes = currentDate.diff(date, 'minutes');
  const diffDays = currentDate.diff(date, 'days');

  let str = '';

  switch (true) {
    case diffDays > 0 && diffDays < 7:
      str = `${diffDays} day ago at ${date.format('HH:MM A')}`;
      break;
    case diffDays >= 7 && diffDays < 30:
      str = `${Math.floor(diffDays / 7)} week ago at ${date.format('HH:MM A')}`;
      break;
    case diffDays >= 30 && diffDays < 365:
      str = `${Math.floor(diffDays / 30)} month ago at ${date.format('HH:MM A')}`;
      break;
    case diffDays >= 365:
      str = `${Math.floor(diffDays / 365)} year ago at ${date.format('HH:MM A')}`;
      break;
    default:
      switch (true) {
        case diffMinutes < 1:
          str = 'few seconds ago';
          break;

        case diffMinutes > 60:
          str = `${Math.floor(diffMinutes / 60)} hours ago`;
          break;

        default:
          str = `${diffMinutes} minutes ago`;
      }
  }

  return str;
};

export const formatDateDayDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);

  return formattedDate;
};
