import { Alert, Button, Col, Form as AntdForm, Input, Row, Typography } from 'antd';
import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../../context/auth';
import { useContextNotifications } from '../../../../context/notifications';
import { JsonResult } from '../../../../types';
import { password } from '../../../../utils/inputRules';
import styles from '../index.module.less';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { openNotification } = useContextNotifications();
  const { code: forgotPasswordCode } = useParams();
  const { resetPassword, loading, error, clearResponseError } = useAuth();

  const handleSubmit = (values: JsonResult) => {
    if (!forgotPasswordCode) return;

    resetPassword({
      password: values.password,
      forgotPasswordCode,
    }).then(() => {
      openNotification?.({ message: 'Password changed successfully', type: 'success' });
      navigate('/sign-in');
    });
  };

  return (
    <AntdForm layout="vertical" onFinish={handleSubmit} className={styles.form} autoComplete="off">
      {error ? (
        <AntdForm.Item>
          <Alert onClose={clearResponseError} message={error} type="error" closable showIcon />
        </AntdForm.Item>
      ) : null}
      <AntdForm.Item name="password" label="New Password" rules={password} hasFeedback>
        <Input.Password size="large" placeholder="Enter New Password" />
      </AntdForm.Item>
      <AntdForm.Item
        name="passwordConfirm"
        label="New Password Confirm"
        hasFeedback
        dependencies={['password']}
        rules={[
          ...password,
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject(new Error('The new password you entered does not match.'));
            },
          }),
        ]}
      >
        <Input.Password size="large" placeholder="Enter New Password Confirm" />
      </AntdForm.Item>
      <AntdForm.Item shouldUpdate>
        {({ getFieldsValue, getFieldsError }) => {
          const { password: passwordValue, passwordConfirm } = getFieldsValue();
          const formIsComplete =
            passwordValue && passwordConfirm && getFieldsError().every((field) => !field.errors.length);

          return (
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                  disabled={!formIsComplete}
                >
                  Reset Password
                </Button>
              </Col>
            </Row>
          );
        }}
      </AntdForm.Item>
      <div className={styles.textBox}>
        <Typography.Text className="color-gray">
          Back to <Link to="/sign-in">Sign In</Link>
        </Typography.Text>
      </div>
    </AntdForm>
  );
};

export default ResetPassword;
