import { Tabs, TabsProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import Stripe from 'stripe';
import { useAuth } from '../../../../context/auth';
import Wrapper from '../index';
import Confirm from './Confirm';
import Payment from './Payment';
// import SelectPlan from './SelectPlan';
import { getDataInStorage } from '../../../../utils/storage';
import { usePlans } from '../../../../hooks/stripe';
import { useContextNotifications } from '../../../../context/notifications';

const Setup = () => {
  const navigate = useNavigate();
  const { isPlanExists } = useAuth();

  const [key, setKey] = useState<number>(1);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>(null);
  const [discount, setDiscount] = useState<string>();
  const getPlans = usePlans();
  const [plan, setPlan] = useState<Stripe.Plan>();
  const { openNotification } = useContextNotifications();
  const { logout } = useAuth();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const plansResponse = await getPlans.fetch({ type: 'month' });
        const planData = plansResponse?.data || [];

        if (planData.length > 0) {
          const sortedPlans = [...planData].sort((a, b) => (a.amount || 0) - (b.amount || 0));

          setSelectedPlan(sortedPlans[0]?.id);

          setPlan(sortedPlans[0]);
        }
      } catch (error) {
        openNotification?.({ message: `Error fetching plans: ${error}`, type: 'error' });
      }
    };

    fetchPlans();
  }, []);

  const hasPLan = getDataInStorage('auth').hasPlan;

  useEffect(() => {
    if (hasPLan) {
      navigate('/', { replace: true });
    }
  }, [isPlanExists]);

  const handleNext = () => {
    setKey((prev) => prev + 1);
  };

  const handleBack = () => {
    // setKey((prev) => prev - 1);
    navigate('/sign-in');
    logout()
  };

  // const handlePlanChange = (id: string, newpromoCode?: string) => {
  //   setSelectedPlan(id);
  //   setpromoCode(newpromoCode);
  //   handleNext();
  // };

  const handlePaymentMethodSelect = (id: string | null) => {
    setSelectedPaymentMethod(id);
    // handleNext();
  };

  const items: TabsProps['items'] = [
    {
      key: '0',
      label: '',
    },
    {
      key: '1',
      label: '',
      children: (
        <Wrapper underline title='Free for 30 Days, then $99 a Month'>
          <Payment
            selectedPaymentMethod={selectedPaymentMethod}
            handleNext={handlePaymentMethodSelect}
            handleBack={handleBack}
            selectedPlan={selectedPlan}
            discount={discount}
            plan={plan}
          />
        </Wrapper>
      ),
    },
    // {
    //   key: '2',
    //   label: '',
    //   children: (
    //     <Wrapper title="Confirm your details">
    //       <Confirm
    //         selectedPlan={selectedPlan}
    //         promoCode={promoCode}
    //         selectedPaymentMethod={selectedPaymentMethod}
    //         handleBack={handleBack}
    //         discount={discount}
    //       />
    //     </Wrapper>
    //   ),
    // },
  ];

  return (
    <Tabs
      tabPosition="bottom"
      centered
      tabBarGutter={10}
      tabBarStyle={{ borderRadius: 4, height: 5 }}
      className="steps"
      activeKey={key.toString()}
      items={items}
    />
  );
};

export default Setup;
