import { useEffect, useState } from 'react';
import { Typography } from 'antd';
import Layout from '../../components/Layout/Main';
import { displayName } from '../../config';
import UserTable from './userTable';
import { useGetDemoCount } from '../../hooks/admin';

const AdminPanel = () => {
  document.title = `${displayName}: Admin Dashboard`;
  const [demoCount, setDemoCount] = useState<number | null>(0)
  const reportDemo = useGetDemoCount?.()

  useEffect(() => {
    reportDemo?.fetch().then((res) => {
      if (res?.data) {
        setDemoCount(res?.data?.demoReportsCount)
      }
    })
  }, [])

  return (
    <Layout>
      <Typography.Title style={{ padding: '12px' }}>Dashboard</Typography.Title>
      <Typography.Title level={3} style={{ padding: '12px' }}>Demo Reports - {demoCount || 0}</Typography.Title>
      <UserTable />
    </Layout>
  );
};

export default AdminPanel;
