import { FetchResponse, FetchSuccess } from '../types';
import { ICategory } from './categories';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchCreate,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';

interface IScript {
  id: number;
  text: string;
  description: string;
  categoryId: number;
  updatedAt: string;
  createdAt: string;
  Category?: ICategory;
  isViewed?: boolean;
}

export interface IScriptsParams {
  id?: string;
}

export interface IScriptParams {
  id?: number;
  text: string;
  description: string;
  categoryId: number;
}

export const useScripts = (): FetchGet<FetchResponse<IScript[]>, IScriptsParams> =>
  useFetchGet('scripts', { autoStart: false, startStateLoading: false, multiple: 'scripts' });

export const useScriptId = (): FetchGetId<FetchResponse<IScript>> =>
  useFetchGetId('scripts', '', { autoStart: false, startStateLoading: false, multiple: 'script' });

export const useScriptCreate = (): FetchCreate<FetchResponse<IScript>, DefaultFetchError, IScriptParams> =>
  useFetchCreate('scripts');

export const useScriptUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, IScriptParams> =>
  useFetchUpdate('scripts', '');
