import { Button, Col, Row, Space } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import React, { useEffect, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useContextReports } from '../../../../../context/reports';
import { IReportBuyDownKey, IReportBuyDownRow, useReportBuyDown } from '../../../../../hooks/reports';
import ContentCard from '../../../../Common/ContentCard';
import MovingTo from '../../../../Common/MovingTo';
import { BUY_DOWN_LABELS } from '../../constants';
import { IBuyDownOption } from '../../types';
import ReportSection from '../Section';
import BuyDownCalculatorTable from './Table';
import Option from './Option';

interface IBuyDownCalculator {
  provided?: DraggableProvided;
}

const BuyDownCalculator = ({ provided }: IBuyDownCalculator) => {
  const name = 'buyDownCalculator';
  const { loans, isBorrower } = useContextReports();
  const [isTableShown, setIsTableShown] = useState<boolean>(false);
  const [data, setData] = useState<IBuyDownOption[]>([]);
  const reportBuyDown = useReportBuyDown();
  const form = useFormInstance();
  const movingTo = useWatch(['movingTo', name], form);
  const isSectionActive = useWatch(['reportView', name, 'active'], form);
  const toggleTableView = () => setIsTableShown((prev) => !prev);

  useEffect(() => {
    if (!movingTo || (!isSectionActive && !loans)) return;
    const loan = loans?.find((item) => item.id === movingTo);

    const delay = 1150;
    const timeoutId = setTimeout(() => {
      reportBuyDown.fetch({ id: loan ? loan.id : loans?.[0].id }).then((response) => {
        if (!response?.data) return;

        const newData = Object.entries(response.data).map(([key, value]) => ({
          key,
          title: BUY_DOWN_LABELS[key as IReportBuyDownKey],
          rows: (value as IReportBuyDownRow[])
            .filter((row) => !row.buyDownCost)
            .map((row, index) => ({
              key: `${key}-${index}`,
              ...row,
            })),
          total: (value as IReportBuyDownRow[]).find((row) => !!row.buyDownCost)?.buyDownCost || '',
        }));

        setData(newData);
      });
    }, delay);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeoutId);
  }, [movingTo, isSectionActive, loans]);

  useEffect(() => {
    if (isBorrower) {
      setIsTableShown(true);
    }
  }, [isBorrower])

  return (
    <ReportSection
      id={name}
      name={name}
      title="Temporary Interest Rate Buydown"
      description="Lower your monthly mortgage payments for 1 to 3 years by reducing your interest 
      rate.  Sellers pay at closing. Unused amounts will be credited to your loan balance if
      you refinance before the end of your buydown term.  Click “show details” to see the
      cost to the seller and your monthly payment savings for different buydown terms"
      provided={provided}
    >
      <Space style={{ padding: '10px' }} direction="vertical" size={16}>
        <Row justify="space-between" align="middle">
          <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
            <Button type="default" size="large" onClick={toggleTableView}>
              {isTableShown ? 'Hide details' : 'Show details'}
            </Button>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
            <MovingTo name={name} />
          </Col>
        </Row>
        <ContentCard loading={!reportBuyDown.data && reportBuyDown.loading}>
          <Space direction="vertical" size={20}>
            <Row gutter={[20, 20]}>
              {data
                .sort((a, b) => a.rows.length - b.rows.length)
                .map((option) => (
                  <Col key={option.key} xl={24 / data.length} span={24} style={{ minWidth: 300 }}>
                    <Option
                      title={option.title}
                      subtitle="Principle & Interest Payment"
                      rows={option.rows}
                      loan={loans?.find((item) => item.id === movingTo)}
                    />
                  </Col>
                ))}
            </Row>
          </Space>
          <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
            <BuyDownCalculatorTable
              data={data}
              isShown={isTableShown}
              loan={loans?.find((item) => item.id === movingTo)}
            />
          </div>
        </ContentCard>
      </Space>
    </ReportSection>
  );
};

BuyDownCalculator.defaultProps = {
  provided: undefined,
};

export default BuyDownCalculator;
