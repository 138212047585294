import { Alert, Button, Col, Form as AntdForm, Input, Row, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../context/auth';
import { useContextNotifications } from '../../../../context/notifications';
import { JsonResult } from '../../../../types';
import { login } from '../../../../utils/inputRules';
import styles from '../index.module.less';

const ForgotPassword = () => {
  const { forgotPassword, loading, error, clearResponseError } = useAuth();
  const { openNotification } = useContextNotifications();

  const handleSubmit = (values: JsonResult) => {
    forgotPassword({
      email: values.email,
    }).then(() => {
      openNotification?.({
        message: 'The email was sent successfully',
        type: 'success',
      });
    });
  };

  return (
    <AntdForm layout="vertical" onFinish={handleSubmit} className={styles.form} autoComplete="off">
      {error ? (
        <AntdForm.Item>
          <Alert onClose={clearResponseError} message={error} type="error" closable showIcon />
        </AntdForm.Item>
      ) : null}
      <AntdForm.Item name="email" label="Email" rules={login}>
        <Input size="large" placeholder="Enter Email" />
      </AntdForm.Item>
      <AntdForm.Item shouldUpdate>
        {({ getFieldsValue, getFieldsError }) => {
          const { email } = getFieldsValue();
          const formIsComplete = email && getFieldsError().every((field) => !field.errors.length);

          return (
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                  disabled={!formIsComplete}
                >
                  Send Email
                </Button>
              </Col>
            </Row>
          );
        }}
      </AntdForm.Item>
      <div className={styles.textBox}>
        <Typography.Text className="color-gray">
          Back to <Link to="/sign-in">Sign In</Link>
        </Typography.Text>
      </div>
    </AntdForm>
  );
};

export default ForgotPassword;
