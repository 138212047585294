import { IReportRow } from '../types/reports';

export const getInputsCount = (tableView: IReportRow[]) => {
  let fieldsCount = 0;

  Object.values(tableView).forEach((row) => {
    if (!row.active || !(row.tabIndex || row.subField?.tabIndex)) return;

    if (row.tabIndex) fieldsCount += 1;
    if (row.subField?.tabIndex) fieldsCount += 1;
    if (row.getPopoverContent) fieldsCount += 1;
  });

  return fieldsCount;
};
