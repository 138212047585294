import { Col, Row } from 'antd';
import Stripe from 'stripe';
import PlanItem from './PlanItem';

interface IPlansDetails {
  plans: Stripe.Plan[];
  selectedPlan?: string;
  handleChange: (id: string) => void;
  loading?: boolean;
}
const features = ['Feature1', 'Feature2', 'Feature3', 'Feature4', 'Feature5', 'Feature6'];

const PlansDetails = ({ plans, selectedPlan, handleChange, loading }: IPlansDetails) => {
  const selectedPlanIndex = plans.findIndex((plan) => plan.id === selectedPlan);

  const isButtonActive = (index: number): boolean => {
    if (index === selectedPlanIndex) return true;

    return false;
  };

  const getButtonLabel = (index: number) => {
    if (!selectedPlan) return 'Select';
    if (selectedPlanIndex > index) return 'Downgrade';

    return 'Upgrade';
  };

  return (
    <section>
      <Row gutter={[20, 20]}>
        {plans
          .sort((a, b) => (a.trial_period_days ? 0 : a.amount || 0) - (b.trial_period_days ? 0 : b.amount || 0))
          .map((plan, index) => (
            <Col span={24} xl={24 / plans.length} key={plan.id}>
              <PlanItem
                loading={loading}
                plan={plan}
                active={isButtonActive(index)}
                handleChange={handleChange}
                features={features.slice(0, (index + 1) * 2)}
                buttonLabel={getButtonLabel(index)}
              />
            </Col>
          ))}
      </Row>
    </section>
  );
};

PlansDetails.defaultProps = {
  selectedPlan: undefined,
  loading: false,
};

export default PlansDetails;
