import Title from 'antd/lib/typography/Title';
import Layout from '../../components/Layout/Main';
import { displayName } from '../../config';
import WeeklyNewsTable from './weeklyNewsTable';

const WeeklySummary = () => {
  document.title = `${displayName}: Weekly news Summary`;

  return (
    <Layout>
      <Title style={{ padding: '12px' }}>Weekly News Summary</Title>
      <WeeklyNewsTable />
    </Layout>
  );
};

export default WeeklySummary;
