import { ProColumns } from '@ant-design/pro-table';
import { Space, Typography } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { IReportLoan } from '../../../../../../hooks/reports';
import { commafy } from '../../../../../../utils/text';
import ContentCard from '../../../../../Common/ContentCard';
import Table from '../../../../../Common/Table';
import { IBuyDownOption, IBuyDownRow } from '../../../types';

import styles from './index.module.less';

interface IBuyDownCalculatorTable {
  data: IBuyDownOption[];
  isShown: boolean;
  loan?: IReportLoan;
}

const BuyDownCalculatorTable = ({ data, isShown, loan }: IBuyDownCalculatorTable) => {
  const columns: ProColumns<IBuyDownRow>[] = [
    {
      title: 'Interests rate',
      dataIndex: 'interestRate',
      sorter: false,
    },
    {
      title: `Payment at ${loan?.interestRate || 0}%`,
      sorter: false,
      dataIndex: 'payment',
      renderText: (payment) => `$${payment === 'NaN' ? 0 : commafy(payment)}`,
    },
    {
      title: 'New Payment',
      dataIndex: 'paymentWithSubstitudy',
      sorter: false,
      renderText: (payment) => `$${commafy(payment)}`,
    },
    {
      title: 'Monthly Payment Reduction',
      dataIndex: 'monthlySubsidy',
      sorter: false,
      renderText: (monthlySubsidy) => (monthlySubsidy === 'NaN' ? 0 : `$${commafy(monthlySubsidy)}`),
    },
    {
      title: '# of payments',
      dataIndex: 'numPaymentsPerYear',
      sorter: false,
    },
    {
      title: 'Annual Payment Reduction',
      dataIndex: 'annualSubsidyPayments',
      sorter: false,
      renderText: (subsidyPayments) => `$${subsidyPayments === 'NaN' ? 0 : commafy(subsidyPayments)}`,
    },
  ];

  return (
    <div className={clsx(styles.table, { [styles.hidden]: !isShown })}>
      <ContentCard bordered overflow="visible">
        <Table<IBuyDownRow>
          search={false}
          columns={columns}
          showSorterTooltip={false}
          toolBarRender={false}
          pagination={false}
          className="table-without-border table-only-header"
          locale={{ emptyText: ' ' }}
        />
        {data.map((table) => (
          <Space key={table.key} direction="vertical" size={0}>
            <div className={styles.header}>
              <Typography.Text>{table.title}</Typography.Text>
            </div>
            <Table<IBuyDownRow>
              search={false}
              columns={columns}
              showSorterTooltip={false}
              toolBarRender={false}
              pagination={false}
              dataSource={table.rows}
              className="table-without-border table-only-content"
            />
            <div className={styles.footer}>
              <Typography.Title level={3}>Buydown costs:</Typography.Title>
              <Typography.Title style={{ color: '#464646' }} level={2}>
                ${table.total === 'NaN' ? 0 : commafy(table.total)}
              </Typography.Title>
            </div>
          </Space>
        ))}
      </ContentCard>
    </div>
  );
};

BuyDownCalculatorTable.defaultProps = {
  loan: undefined,
};

export default BuyDownCalculatorTable;
