import { FetchResponse } from '../types';
import {
    DefaultFetchError,
    FetchCreate,
    FetchGet,
    useFetchCreate,
    useFetchGet,
} from './fetch';
import { IReport } from './reports';

export interface ICreateResponse {
    data: { message: string };
}

export interface ICreateViewParams {
    email?: string;
    reportId?: string;
    name: string;
    viewIp: string;
}

export interface IGetViewParams {
    reportId: string;
    viewIp: string;
}

export interface IGetResponse {
    alreadyViewed: boolean;
}

export interface IUserViews {
    id: number;
    name: string;
    email: string | null;
    createdAt: string;
    updatedAt: string;
    viewIp: string;
}

export interface IAllViewed {
    id: IReport['id'];
    name: IReport['name'];
    status: IReport['status'];
    createdAt: IReport['createdAt'];
    UserViews: IUserViews[];
}

interface IAllViewedResponse {
    viewedReports: IAllViewed[];
}

export interface IRecentlyViewed extends IUserViews {
    Report: IReport;
}

interface IRecentlyViewedResponse {
    recentlyViewed: IRecentlyViewed[];
}

export interface IMostViewed {
    id: IReport['id'];
    name: IReport['name'];
    viewCount: number;
}

interface IMostViewedResponse {
    mostViewedReports: IMostViewed[];
    total: number;
    page: number;
    pageSize: number;
}

export interface IMostViewedParams {
    order: 'ASC' | 'DESC';
    page: number;
    pageSize: number;
    timeFilter: EnumTimeOptions;
}

export enum EnumTimeOptions {
    ALL_TIME = 'all_time',
    One_Week = 'one_week',
    Two_Weeks = 'two_weeks',
    One_Month = 'one_month',
    Two_Months = 'two_months',
    Three_months = 'three_months',
    Six_Months = 'six_months',
    One_Year = 'one_year',
}

export const useUserViewCreate = ():
    FetchCreate<FetchResponse<ICreateResponse>, DefaultFetchError, ICreateViewParams> =>
    useFetchCreate('userViews')

export const useUserViewGet = (): FetchGet<FetchResponse<IGetResponse>, IGetViewParams> =>
    useFetchGet('userViews', { autoStart: false });

export const useGetAllViews = (): FetchGet<FetchResponse<IAllViewedResponse>> =>
    useFetchGet('userViews/all-viewed', { autoStart: false });

export const useGetRecentViews = (): FetchGet<FetchResponse<IRecentlyViewedResponse>> =>
    useFetchGet('userViews/recently-viewed', { autoStart: false });

export const useGetMostViewed = (): FetchGet<FetchResponse<IMostViewedResponse>, IMostViewedParams> =>
    useFetchGet('userViews/most-viewed', { autoStart: false });