import Icon from '@ant-design/icons';
import { Button, Col, Row, Space, Steps, StepsProps, Typography } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../context/auth';
import { useReports } from '../../../../hooks/reports';
import { Back } from '../../../Common/Icon';
import Loading from '../../../Common/Loading';
import ReportRow from '../Reports/Row';

const customDot: StepsProps['progressDot'] = (dot) => dot;

const Activities = () => {
  const navigate = useNavigate();

  const { user } = useAuth();
  const { data, fetch, loading } = useReports();

  useEffect(() => {
    fetch({ userId: user.id, resent: true });
  }, []);

  const handleBack = () => {
    navigate({ pathname: '/home' });
  };

  const steps = data?.data.rows.map((report) => ({
    status: 'finish' as 'wait' | 'process' | 'finish' | 'error',
    description: (
      <div style={{ paddingTop: '2rem', padding: '10px' }}>
        <ReportRow report={report} isLast />
      </div>
    ),
    className: 'steps',
  }));

  return (
    <section>
      <Loading visible={loading} absolute />
      <Row>
        <Col span={24}>
          <Space direction="vertical" size={24}>
            <Space size={4}>
              <Button
                type="ghost"
                icon={<Icon component={Back} style={{ fontSize: '1.25em' }} />}
                onClick={handleBack}
              />
              <Typography.Title>Activities</Typography.Title>
            </Space>
            <Steps
              direction="vertical"
              progressDot={customDot}
              items={[...(steps || []), { status: 'finish', className: 'd-none' }]}
            />
          </Space>
        </Col>
      </Row>
    </section>
  );
};

export default Activities;
