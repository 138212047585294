import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

import {
  IProfile,
  IProfileUpdateParams,
  IUserSettings,
  IUserSettingsParams,
  useProfileId,
  useProfileUpdate,
  useUserSettings,
  useUserSettingsUpdate,
} from '../hooks/users';
import { FetchResponse } from '../types';

interface UsersContext {
  settings?: IUserSettings;
  getSettings?: () => Promise<FetchResponse<IUserSettings> | null>;
  updateSettings?: (
    params: IUserSettingsParams,
    id: string | number
  ) => Promise<void> | Promise<FetchResponse<string> | null>;
  settingsLoading: boolean;
  settingsUpdateLoading: boolean;

  profile: IProfile | null;
  getProfile?: () => Promise<void>;
  updateProfile?: (params: IProfileUpdateParams, id: string) => Promise<void>;
  profileUpdateLoading: boolean;
  profileIdLoading: boolean;
}

const defaultValue = {
  settings: undefined,
  getSettings: undefined,
  updateSettings: undefined,
  settingsLoading: false,
  settingsUpdateLoading: false,

  profile: null,
  getProfile: undefined,
  updateProfile: undefined,
  profileUpdateLoading: false,
  profileIdLoading: false,
};

export const UsersContext = createContext<UsersContext>(defaultValue);

const UsersProvider = ({ children }: PropsWithChildren) => {
  const [profile, setProfile] = useState<IProfile | null>(null);
  const profileId = useProfileId();
  const profileUpdate = useProfileUpdate();
  const userSettings = useUserSettings();
  const userSettingsUpdate = useUserSettingsUpdate();

  const getProfile = () =>
    profileId.fetch().then((data) => {
      setProfile(data?.data || null);
    });

  const getSettings = () => userSettings.fetch();

  const updateSettings = (params: IUserSettingsParams, id: string | number) => userSettingsUpdate.fetch(params, id);

  const updateProfile = (params: IProfileUpdateParams, id: string) =>
    profileUpdate.fetch(params, id).then(() => {
      getProfile();
    });

  return (
    <UsersContext.Provider
      value={{
        settings: userSettings.data?.data,
        updateSettings,
        settingsLoading: userSettings.loading,
        settingsUpdateLoading: userSettingsUpdate.loading,
        getSettings,
        profile,
        getProfile,
        updateProfile,
        profileUpdateLoading: profileUpdate.loading,
        profileIdLoading: profileId.loading,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export default UsersProvider;

export const useContextUsers = (): UsersContext => useContext(UsersContext);
