import { Button, Space, Table, Typography } from 'antd';
import moment from 'moment';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ContentCard from '../../../Common/ContentCard';
import { IRecentlyViewed, useGetRecentViews } from '../../../../hooks/userViews';

const HomeViews = () => {
  const navigate = useNavigate();
  const { fetch, data, loading } = useGetRecentViews();

  useEffect(() => {
    fetch()
  }, []);

  const handleViewAll = () => {
    navigate('/home/viewed');
  };

  const columns: ColumnsType<IRecentlyViewed> = [
    {
      align: 'center' as const,
      title: 'Report Name',
      dataIndex: 'Report',
      key: 'reportName',
      render: (report: { name: string }) => report?.name || "(UNNAMED REPORT)",
    },
    {
      align: 'center' as const,
      title: 'Viewer',
      key: 'viewer',
      render: (record: IRecentlyViewed) => (
        <>
          <div>{record.name || 'N/A'}</div>
          <div>{record.email || 'N/A'}</div>
        </>
      ),
    },
    {
      align: 'center' as const,
      title: 'Date Viewed',
      dataIndex: 'createdAt',
      key: 'dateViewed',
      render: (dom: React.ReactNode) => typeof dom === 'string' ? moment(dom).format('ddd DD MMM, YYYY hh:mm a') : dom,
    },
  ];

  return (
    <ContentCard bordered padding={20} height="100%">
      <Space direction="vertical" size={20}>
        <div className="flex-row flex-justify-space-between gap-20">
          <Typography.Title level={2} style={{ color: '#464646' }}>
            Recently Viewed Reports
          </Typography.Title>
          <Button type="text" className="color-gray uppercase" onClick={handleViewAll}>
            View All
          </Button>
        </div>
        <div className="position-relative flex-1">
            <Table<IRecentlyViewed>
              size='small'
              loading={loading}
              dataSource={data?.data.recentlyViewed || []}
              columns={columns}
              rowKey={record => `${record.id}-${record.Report.id}`}
              pagination={false}
            />
        </div>
      </Space>
    </ContentCard>
  )
}

export default HomeViews;
