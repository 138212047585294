import { Col, Form, Radio, RadioChangeEvent, Row, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import debounce from "just-debounce-it";
import { useAuth } from '../../../../context/auth';
import { useContextUsers } from '../../../../context/users';
import {ISettingItem, useChangeDefaultReportType } from '../../../../hooks/users';
import { JsonResult } from '../../../../types';
import SettingsList from './SettingsList';
import { editorModules } from '../../Reports/Sections/WelcomeText';
import { REPORT_TYPE_OPTIONS } from '../../Reports/constants';
import { EnumReportTypes } from '../../../../types/reports';

import 'react-quill/dist/quill.snow.css';
import './style.less';

type TextType = 'closingText' | 'welcome_message';

const Settings = () => {
  const {user: { id }} = useAuth();
  const [updLoading, setUpdLoading] = useState(true);
  const { settings, getSettings, updateSettings, settingsLoading, profile, updateProfile } = useContextUsers();
  const [welcomeHtmlContent, setWelcomeHtmlContent] = useState('');
  const [closingHtmlContent, setClosingHtmlContent] = useState('');
  const [defaultReportType, setDefaultReportType] = useState<EnumReportTypes>();
  const updateUserDefaultReportType = useChangeDefaultReportType();

  useEffect(() => {
    if (settings || !getSettings) return;
    getSettings();
  }, []);

  useEffect(() => {
    if (profile) {
      setWelcomeHtmlContent(profile.welcome_message || '');
      setClosingHtmlContent(profile.closingText || '');
      setDefaultReportType(profile?.reportDefaultType);
    }
  }, [profile]);

  const handleUpdate = (type: string, rows: ISettingItem[]) => {
    setUpdLoading(false);
    updateSettings?.({ ...settings, [type]: rows }, id).finally(() => {
      getSettings?.().finally(() => setUpdLoading(true));
    });
  };

  const handleUpdateProfile = (params: JsonResult) => {
    if (!profile || !updateProfile) return;

    const { email } = profile;

    updateProfile({ email, ...params }, id);
  };

  const handleTextChange = (textType: TextType, htmlContent: string) => {
    if (textType === 'closingText') {
      setClosingHtmlContent(htmlContent);
      handleUpdateProfile({ closingText: htmlContent });
    } else if (textType === 'welcome_message') {
      setWelcomeHtmlContent(htmlContent);
      handleUpdateProfile({ welcome_message: htmlContent });
    }
  };

  const debouncedHandleTextChange = debounce((textType: TextType, htmlContent: string) => {
    handleTextChange(textType, htmlContent);
  }, 1000);

  const onDefaultTypeChange = ({ target }: RadioChangeEvent) => {
    const value = target.value as EnumReportTypes;

    setDefaultReportType(value);
    setUpdLoading(true);

    updateUserDefaultReportType.fetch({ reportType: value }).then(() => {
      getSettings?.().finally(() => setUpdLoading(false));
    });
  };

  return (
    <Row gutter={[20, 20]}>
      <Col xl={8} span={24}>
        <Space direction="vertical" size={20}>
          <Typography.Title level={3}>Default Report Type</Typography.Title>
          <Radio.Group
            options={REPORT_TYPE_OPTIONS}
            onChange={onDefaultTypeChange}
            value={defaultReportType}
            optionType="button"
            buttonStyle="solid"
            className='custom-radio-group'
          />
          <SettingsList
            loading={settingsLoading && updLoading}
            items={settings?.marketNotification || []}
            label=""
            description="Receive interest rate reprice alerts."
            handleUpdate={(rows) => handleUpdate('marketNotification', rows)}
          />
          <SettingsList
            loading={settingsLoading && updLoading}
            items={settings?.generalNotification || []}
            label="General notification"
            handleUpdate={(rows) => handleUpdate('generalNotification', rows)}
          />
          <SettingsList
            loading={settingsLoading && updLoading}
            draggable
            items={settings?.tableView || []}
            label="Default table view "
            description="Adjust how you view the report table, and you can also change their order by dragging them"
            handleUpdate={(rows) => handleUpdate('tableView', rows)}
          />
        </Space>
      </Col>
      <Col xl={{ span: 9, offset: 4 }} span={24} className="flex-col gap-20">
        <SettingsList
          loading={settingsLoading && updLoading}
          draggable
          items={settings?.reportView || []}
          label="Default report view"
          description="Adjust how you view the open report, and you can also change their order by dragging them"
          handleUpdate={(rows) => handleUpdate('reportView', rows)}
        />
        <div>
          <div className="flex-col gap-4">
            <Typography.Text className="fs-0875 fw-500">Welcome Text Default</Typography.Text>
            <Form.Item name={['profile', 'welcome_message']} label="Enter Text" noStyle>
              <div className="custom-quill-editor" style={{ marginBottom: '15px' }}>
                <ReactQuill
                  modules={editorModules}
                  theme="snow"
                  value={welcomeHtmlContent}
                  onChange={(content) => debouncedHandleTextChange('welcome_message', content)}
                  placeholder="Enter welcome text"
                />
              </div>
            </Form.Item>
          </div>
          <div className="flex-col gap-4">
            <Typography.Text className="fs-0875 fw-500">Closing Text Default</Typography.Text>
            <Form.Item name={['profile', 'closingText']} label="Enter Text" noStyle>
              <div className="custom-quill-editor">
                <ReactQuill
                  modules={editorModules}
                  theme="snow"
                  value={closingHtmlContent}
                  onChange={(content) => debouncedHandleTextChange('closingText', content)}
                  placeholder="Enter closing text"
                />
              </div>
            </Form.Item>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Settings;
