import Details from '../../../components/Pages/News/details';
import Layout from '../../../components/Layout/Main';
import { displayName } from '../../../config';

const NewsDetails = () => {
  document.title = `${displayName}: News details`;

  return (
    <Layout>
      <Details />
    </Layout>
  );
};

export default NewsDetails;
