import { FetchResponse } from '../types';
import { FetchGet, useFetchGet } from './fetch';
import { IReportLongTermParams } from './reports';

export interface INotificationItem {
  id: number;
  title: string;
  type: string;
  createdAt: string;
  description: string;
  referral?: string;
  link?: string;
}

interface INotificationsByTypeParams {
  type: string;
}

export const useNotifications = (): FetchGet<FetchResponse<INotificationItem[]>, IReportLongTermParams> =>
  useFetchGet('notifications', {
    autoStart: false,
    startStateLoading: false,
    decorateData: (res: FetchResponse<INotificationItem[]>) => ({
      ...res,
      data: res.data.map((item) => ({ ...item, type: item.type.toLowerCase() })),
    }),
  });

export const useNotificationsByType = (): FetchGet<FetchResponse<INotificationItem[]>, INotificationsByTypeParams> =>
  useFetchGet('notifications/notification', { autoStart: false, startStateLoading: false });
