import { Tag, Typography } from 'antd';
// import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { JsonResult } from '../../../../types';
import ContentCard from '../../../Common/ContentCard';

interface ITile {
  script: JsonResult;
}

const Tile = ({ script }: ITile) => {
  const colorBlue = getComputedStyle(document.documentElement).getPropertyValue('--color-blue');

  return (
    <ContentCard padding={12} bordered height="100%">
      <Link to={`/content-scripts/${script.id}`}>
        <div className="flex-col gap-4">
          <div className="flex-row flex-align-center gap-12 flex-wrap flex-justify-space-between">
            <Typography.Text className="color-gray fs-0875 uppercase fw-500 ws-nowrap">
              {script.Category.name}
            </Typography.Text>
            <div className="flex-row flex-align-center gap-12 ws-nowrap">
              {/* <Typography.Text className="color-gray fs-0875 uppercase fw-500 ws-nowrap">
                {moment(script.createdAt).format('MMM DD, YYYY')}
              </Typography.Text> */}
              {!script.isViewed && (
                <Tag color={colorBlue} className="m-0">
                  New
                </Tag>
              )}
            </div>
          </div>
          <Typography.Text className="fs-0875 uppercase fw-500">{script.description}</Typography.Text>
        </div>
      </Link>
    </ContentCard>
  );
};

export default Tile;
