import Layout from '../../../components/Layout/Auth';
import Wrapper from '../../../components/Pages/Auth';
import Content from '../../../components/Pages/Auth/SignIn';
import { displayName } from '../../../config';

const SignIn = (): JSX.Element => {
  document.title = `${displayName}: Sign In`;

  return (
    <Layout>
      <Wrapper title="Sign in" text="Welcome back to Mortgage Maker">
        <Content />
      </Wrapper>
    </Layout>
  );
};

export default SignIn;
