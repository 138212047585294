import clsx from 'clsx';
import styles from '../index.module.less';

interface ICategory {
  isActive?: boolean;
  label: string;
  onClick?: () => void;
}

const Category = ({ isActive, label, onClick }: ICategory) => (
  <div className={clsx(styles.category, { [styles.active]: isActive })} onClick={onClick}>
    {label}
  </div>
);

Category.defaultProps = {
  isActive: false,
  onClick: undefined,
};

export default Category;
