/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { Button, Input, Typography } from 'antd';

import './mainContent.less';
import { useUpdateNews } from '../../../../../hooks/news';

type MainContentProps = {
  body?: string;
  id?: number;
  toggleUpdate: () => void;
};

export const MainContent: React.FC<MainContentProps> = ({ body, id, toggleUpdate }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editDetails, setEditDetails] = useState({ body });
  const update = useUpdateNews();

  const handleEdit = () => setIsEdit(true);

  const handleSave = () => {
    update
      .fetch(
        {
          body: editDetails.body,
        },
        id
      )
      .then(() => {
        toggleUpdate?.();
        setIsEdit(false);
      });
  };

  const handleCancel = () => {
    setIsEdit(false);
    setEditDetails({ body });
  };

  const handleChange = (key: string, value: string) => {
    setEditDetails((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <div className="main-content-container">
      <Typography.Text className="conclusion-text">Conclusion</Typography.Text>
      {isEdit ? (
        <div className="inputWrapper">
          <Input.TextArea
            rows={5}
            size="large"
            className="textarea-edit"
            value={editDetails.body}
            onChange={(e) => handleChange('body', e.target.value)}
          />
        </div>
      ) : (
        <Typography.Paragraph className="paragraph-text">
          <div dangerouslySetInnerHTML={{ __html: editDetails.body || '' }} />
        </Typography.Paragraph>
      )}
      {isEdit ? (
        <div className="actions-container">
          <Button size="small" type="primary" disabled={!editDetails.body} onClick={handleSave}>
            Save
          </Button>
          <Button size="small" type="primary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      ) : (
        <div className="actions-container">
          <Button size="small" type="primary" onClick={handleEdit}>
            Edit
          </Button>
        </div>
      )}
    </div>
  );
};

MainContent.defaultProps = {
  body: undefined,
  id: undefined,
};
