import { Col, Row } from 'antd';
import { useContextUsers } from '../../../../context/users';
import CancelSubscription from './CancelSubscription';
import CurrentPlan from './CurrentPlan';
import InvoicesTable from './InvoicesTable';
import PaymentMethod from './PaymentMethod';

const Payment = () => {
  const { profile } = useContextUsers();

  return (
    <Row gutter={[20, 20]}>
      <Col xl={12} span={24}>
        <CurrentPlan />
      </Col>
      <Col xl={12} span={24}>
        <PaymentMethod />
      </Col>
      <Col span={24}>
        <InvoicesTable />
      </Col>
      {profile?.subscriptionId && (
        <Col span={24}>
          <CancelSubscription />
        </Col>
      )}
    </Row>
  );
};

export default Payment;
