import { useRef, useState } from 'react';
import { Divider } from 'antd';
import { IActivity, IReport } from '../../../../../hooks/reports';
import { Comment, Edit, Eye, Quote } from '../../../../Common/Icon';
import Tag from '../../Reports/Tag';

interface IReportRow {
  report: IReport;
}

const ReportRow = ({ report }: IReportRow) => {
  const rowRef = useRef<HTMLDivElement | null>(null);
  const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState<boolean>(true);
  const [isRightArrowDisabled, setIsRightArrowDisabled] = useState<boolean>(false);

  const handleScroll = () => {
    if (!rowRef.current) return;

    if (rowRef.current.scrollLeft === 0 && !isLeftArrowDisabled) {
      setIsLeftArrowDisabled(true);
    }

    if (rowRef.current.scrollLeft > 0 && isLeftArrowDisabled) {
      setIsLeftArrowDisabled(false);
    }

    if (
      rowRef.current.scrollWidth === rowRef.current.clientWidth + rowRef.current.scrollLeft &&
      !isRightArrowDisabled
    ) {
      setIsRightArrowDisabled(true);
    }

    if (rowRef.current.scrollWidth > rowRef.current.clientWidth + rowRef.current.scrollLeft && isRightArrowDisabled) {
      setIsRightArrowDisabled(false);
    }
  };

  const getActivity = (activity: IActivity) => {
    switch (activity.type) {
      case 'viewed':
        return <Tag label="Viewed" icon={Eye} date={activity.date} />;

      case 'edited':
        return <Tag label="Edited" icon={Edit} date={activity.date} />;

      case 'hoiquote':
        return <Tag label="Ordered HOI Quote" icon={Quote} date={activity.date} url={activity.url} />;

      default:
        return <Tag label="Created" icon={Comment} date={activity.date} />;
    }
  };

  return (
    <div>
      <div className="overflow-auto flex-col gap-10" ref={rowRef} onScroll={handleScroll}>
        {!!report.Activities?.length &&
          report.Activities.map((activity) => <div key={activity.id}>{getActivity(activity)}</div>)}
      </div>
      <Divider />
    </div>
  );
};

export default ReportRow;
