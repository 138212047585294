/* eslint-disable @typescript-eslint/ban-ts-comment */
import Icon from '@ant-design/icons';
import { AutoComplete, Button, Input, Typography } from 'antd';
import Form from 'antd/es/form';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { useCategories, useCategoryCreate } from '../../../../hooks/categories';
import { IScriptParams, useScriptCreate, useScriptId, useScripts, useScriptUpdate } from '../../../../hooks/scripts';
import { required } from '../../../../utils/inputRules';
import { Back } from '../../../Common/Icon';
import Loading from '../../../Common/Loading';
import styles from '../index.module.less';
import List from '../List';
import { editorModuleReadOnly, editorModules } from '../../Reports/Sections/WelcomeText';
import { useContextUsers } from '../../../../context/users';

import 'react-quill/dist/quill.snow.css';
import './style.less';

const Details = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { id: scriptId } = useParams();
  const categories = useCategories();
  const categoryCreate = useCategoryCreate();
  const scriptById = useScriptId();
  const scriptCreate = useScriptCreate();
  const scriptUpdate = useScriptUpdate();
  const scripts = useScripts();
  const { profile, getProfile } = useContextUsers();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [editorHtml, setEditorHtml] = useState<string | null | undefined>();

  useEffect(() => {
    if (!scriptById) return;
    const htmlContent = scriptById.data?.data?.text;

    if (htmlContent) {
      setEditorHtml(htmlContent);
    }
  }, [scriptById]);

  useEffect(() => {
    if (!getProfile) return;

    getProfile();
  }, []);

  useEffect(() => {
    categories.fetch();
    scripts.fetch();
  }, []);

  const handleBack = () => {
    navigate('/content-scripts');
  };

  const loadScript = () => {
    scriptById.fetch(undefined, scriptId).then((res) => {
      if (!res?.data) return;
      const { text, description } = res.data;

      form.setFieldsValue({ text, description, category: res?.data?.Category?.name });
    });
  };

  useEffect(() => {
    if (!scriptId) {
      setIsEditMode(true);

      return;
    }

    loadScript();
  }, [scriptId]);

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleEditCancel = () => {
    setIsEditMode(false);
    form.resetFields();
  };

  const handleScriptSave = (values: IScriptParams, categoryId: number) => {
    const { text, description } = values;
    const scriptData = {
      text,
      description,
      categoryId,
    };

    if (scriptId) {
      scriptUpdate.fetch(scriptData, scriptId).then(() => {
        loadScript();
      });
    } else {
      scriptCreate.fetch(scriptData).then((res) => {
        navigate(`/content-scripts/${res?.data.id}`);
      });
    }
    setIsEditMode(false);
  };

  const handleEditSave = () => {
    form.validateFields().then((values) => {
      const categoryId = categories.data?.data.find((item) => item.name === values.category)?.id;

      if (categoryId) {
        handleScriptSave(values, categoryId);
      } else {
        categoryCreate.fetch({ name: values.category }).then((res) => {
          if (!res?.data.id) return;

          handleScriptSave(values, res.data.id);
        });
      }
    });
  };

  const handleEditorChange = (text: string) => {
    setEditorHtml(text);

    form.setFieldsValue({ text });
  };

  const scriptData = scriptById.data?.data;
  const initialData = {
    description: scriptData?.description,
    category: scriptData?.Category?.name,
    text: scriptData?.text,
  };

  return (
    <div className="flex-col gap-24 h-100">
      <Loading
        visible={categories.loading || scriptById.loading || scriptUpdate.loading || scriptCreate.loading}
        absolute
      />
      <div className="flex-row flex-align-center gap-4">
        <Button type="ghost" icon={<Icon component={Back} style={{ fontSize: '1.25em' }} />} onClick={handleBack} />
      </div>

      <div className="flex-1">
        <Form form={form} initialValues={initialData}>
          <div className="flex-col flex-align-center flex-1">
            <div className={styles.scriptDetails}>
              <Form.Item name="description" rules={required('script name')} className="table-form-item flex-1">
                <Input.TextArea
                  autoSize={{ minRows: 1, maxRows: 3 }}
                  className="title-input fs-2 fw-600 p-0"
                  placeholder="Enter script title"
                  disabled={!isEditMode}
                />
              </Form.Item>
              <div className="flex-row gap-12 flex-align-center">
                {scriptData?.createdAt && (
                  <Typography.Text>{moment(scriptById.data?.data?.createdAt).format('MMM DD, YYYY')}</Typography.Text>
                )}
                {isEditMode ? (
                  <div className="m-l-auto">
                    <Form.Item name="category" noStyle>
                      <AutoComplete
                        className="w-min-200"
                        options={categories.data?.data.map((category) => ({
                          label: category.name,
                          value: category.name,
                        }))}
                        allowClear={false}
                      />
                    </Form.Item>
                  </div>
                ) : (
                  <Typography.Text className="color-gray fs-0875 uppercase fw-500 ws-nowrap m-l-auto">
                    {scriptData?.Category?.name}
                  </Typography.Text>
                )}
              </div>
              {isEditMode ? (
                <Form.Item
                  name="text"
                  rules={required('script text')}
                  className="table-form-item flex-1 custom-quill-editor"
                >
                  <ReactQuill
                    theme="snow"
                    value={editorHtml || ''}
                    onChange={handleEditorChange}
                    modules={editorModules}
                    placeholder="Enter script text"
                  />
                </Form.Item>
              ) : (
                <div className="custom-quill-editor">
                  <ReactQuill theme="snow" value={editorHtml || ''} modules={editorModuleReadOnly} readOnly />
                </div>
              )}
              <div className="w-100 flex-row flex-justify-end gap-12">
                {!isEditMode && !!profile && profile.type === 'admin' ? (
                  <Button size="large" type="primary" onClick={handleEdit}>
                    Edit
                  </Button>
                ) : null}

                {isEditMode && !!profile && profile.type === 'admin' && (
                  <>
                    <Button size="large" type="default" onClick={handleEditCancel}>
                      Cancel
                    </Button>
                    <Button size="large" type="primary" onClick={handleEditSave}>
                      Save
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </Form>
      </div>

      <div className="flex-col gap-16">
        <Typography.Title level={2}>You may also like</Typography.Title>
        {scripts.data?.data && <List scripts={scripts.data.data} />}
      </div>
    </div>
  );
};

export default Details;
