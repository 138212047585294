import { Tag } from 'antd';
import React from 'react';
import { capitalize } from '../../../../utils/text';

const colors = {
  completed: {
    text: 'var(--color-tag-green)',
    background: 'var(--color-tag-light-green)',
  },
  created: {
    text: 'var(--color-tag-purple)',
    background: 'var(--color-tag-light-purple)',
  },
  edited: {
    text: 'var(--color-tag-yellow)',
    background: 'var(--color-tag-light-yellow)',
  },
  draft: {
    text: 'var(--color-tag-gray)',
    background: 'var(--color-tag-light-gray)',
  },
};

interface IStatus {
  status: 'completed' | 'created' | 'edited' | 'draft';
}

const Status = ({ status }: IStatus) => (
  <Tag color={colors[status]?.background} style={{ color: colors[status]?.text, padding: '4px 8px' }}>
    {capitalize(status.replace('-', ' '))}
  </Tag>
);

export default Status;
