import { Button, Col, Form, Image, Input, Row, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useReportId } from '../../../hooks/reports';
import { useCreateReferral } from '../../../hooks/users';
import { email } from '../../../utils/inputRules';
import Loading from '../../Common/Loading';

import styles from './index.module.less';

const Referral = () => {
  const [form] = useForm();
  const { id } = useParams();
  const { data, fetch, loading } = useReportId();
  const createReferral = useCreateReferral();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (!id) return;

    fetch(undefined, id);
  }, [id]);

  const handleSubmit = () => {
    if (!id) return;

    form.validateFields().then((values) => {
      createReferral.fetch({ ...values, id }).then(() => {
        setIsSubmitted(true);
      });
    });
  };

  return (
    <div className="flex-col h-100">
      <Loading visible={loading} absolute />
      <div className={styles.header}>
        <div className="content-lg">
          <div className="flex-row flex-justify-space-between">
            <div className="flex-row flex-align-center gap-16">
              <div className={styles.avatarWrapper}>
                <Image
                  preview={false}
                  src={data?.data?.User?.avatar || '/avatar-placeholder.png'}
                  alt="Loan Officer Avatar"
                  height={56}
                  width={56}
                />
              </div>
              <Typography.Title level={2}>
                {data?.data?.User?.firstName} {data?.data?.User?.lastName}
              </Typography.Title>
            </div>
            <div className="flex-row gap-8">
              <Image
                preview={false}
                src={data?.data?.User?.logo || '/logo.png'}
                alt="Company Logo"
                height={96}
                style={{ width: 'auto' }}
              />
              <div className="flex-col gap-8">
                <Typography.Title level={2}>{data?.data?.User?.companyName}</Typography.Title>
                {data?.data?.User?.websiteLink && (
                  <Typography.Text>
                    <Link
                      to={
                        data?.data?.User?.websiteLink.startsWith('http')
                          ? data?.data?.User?.websiteLink
                          : `https://${data?.data?.User?.websiteLink}`
                      }
                      target="_blank"
                      className="fs-1 fw-400 color-gray"
                    >
                      {data?.data?.User?.websiteLink}
                    </Link>
                  </Typography.Text>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-lg flex-col flex-1">
        <Row className="h-100">
          <Col span={5} className="h-100">
            <div className={styles.sidebar}>
              <div className="flex-col gap-20">
                <div className="flex-col gap-8">
                  <Typography.Text className="fs-0875 color-gray uppercase">Phone</Typography.Text>
                  <Typography className="fs-1 color-dark">{data?.data?.User?.phoneNumber || ' - '}</Typography>
                </div>
                <div className="flex-col gap-8">
                  <Typography.Text className="fs-0875 color-gray uppercase">Email</Typography.Text>
                  <Typography className="fs-1 color-dark">{data?.data?.User?.email || ' - '}</Typography>
                </div>
                <div className="flex-col gap-8">
                  <Typography.Text className="fs-0875 color-gray uppercase">Address</Typography.Text>
                  <Typography className="fs-1 color-dark">{data?.data?.User?.address || ' - '}</Typography>
                </div>
                <div className="flex-col gap-8">
                  <Typography.Text className="fs-0875 color-gray uppercase">NMLS License</Typography.Text>
                  <Typography className="fs-1 color-dark">{data?.data?.User?.nmlsId || ' - '}</Typography>
                </div>
              </div>
            </div>
          </Col>
          <Col span={19}>
            <div className={styles.formWrapper}>
              {isSubmitted ? (
                <div className="flex-col gap-16">
                  <Typography.Title level={2}>Got it!</Typography.Title>
                  <Typography.Text>
                    I will reach out to you shortly to discuss your mortgage requirements. Would you like to have a
                    conversation now? Please feel free to call me at the number provided in my profile above. Rest
                    assured, I am here to assist you in any way you require.{' '}
                  </Typography.Text>
                  <Typography.Text>Talk soon</Typography.Text>
                </div>
              ) : (
                <div className="flex-col gap-16">
                  <Typography.Title level={2}>
                    Thank you for considering our mortgage lending services.
                  </Typography.Title>
                  <Typography.Text>Please fill out the form below so we can better assist you.</Typography.Text>
                  <Form layout="vertical" form={form}>
                    <Form.Item name="fullName" label="Full Name">
                      <Input placeholder="Enter name" />
                    </Form.Item>
                    <Form.Item name="email" label="Email Address" rules={email}>
                      <Input placeholder="Enter email" />
                    </Form.Item>
                    <Form.Item name="phoneNumber" label="Phone Number">
                      <Input placeholder="Phone Number" />
                    </Form.Item>
                    <Form.Item name="comment" label="Comment">
                      <Input.TextArea placeholder="Enter comment" />
                    </Form.Item>
                    <div className="flex-row flex-justify-end">
                      <Button size="large" type="primary" onClick={handleSubmit}>
                        Save
                      </Button>
                    </div>
                  </Form>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Referral;
