import { useRef } from 'react';
import Title from 'antd/lib/typography/Title';
import Layout from '../../components/Layout/Main';
import { displayName } from '../../config';
import NewsList from '../../components/Pages/News/newsList';

const breakingNews = () => {
  document.title = `${displayName}: Breaking News`;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const scrlRef = useRef<HTMLDivElement | null>(null);

  const scrollToTop = () => {
    if (scrlRef.current) {
      scrlRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }

  return (
    <Layout>
      <Title ref={scrlRef}> Breaking News</Title>
      <NewsList scrollToTop={scrollToTop} />
    </Layout>
  );
};

export default breakingNews;
