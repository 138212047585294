import Layout from '../../../components/Layout/Main';
import Content from '../../../components/Pages/Home/RecentlyViewed';
import { displayName } from '../../../config';

const RecentlyViewed = (): JSX.Element => {
  document.title = `${displayName}: Recently Viewed reports`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default RecentlyViewed;
