import { ProColumns } from '@ant-design/pro-table';
import { Select, Typography } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import React from 'react';
import { useContextReports } from '../../../../../../context/reports';
import CustomInput from '../../../../../Common/CustomInput';
import Table from '../../../../../Common/Table';
import { IEvent } from '../../../types';
import { IReportLoan } from '../../../../../../hooks/reports';

interface IClosingCostTable {
  events: IEvent[];
  loanKey: number;
  loan: IReportLoan;
}

const ClosingCostTable = ({ events, loanKey, loan }: IClosingCostTable) => {
  const { isBorrower, handleLoansUpdate } = useContextReports();
  const columns: ProColumns<IEvent>[] = [
    {
      title: '',
      width: 40,
      dataIndex: 'color',
      sorter: false,
      renderText: (color) => <div style={{ width: 40, height: 8, borderRadius: 4, background: color }} />,
    },
    {
      title: 'day',
      dataIndex: 'day',
      sorter: false,
      render: (_, event) =>
        isBorrower ? (
          <Typography>{event.day}</Typography>
        ) : (
          <FormItem name={['loans', loanKey, `${event.name}_day`]} className="table-form-item">
            <Select
              // placeholder={event.day}
              // defaultValue={event.day}
              value={event.day}
              options={Array.from({ length: 60 }, (__, i) => i + 1).map((item) => ({
                value: item.toString(),
                label: item,
              }))}
              onSelect={() => handleLoansUpdate?.()}
            />
          </FormItem>
        ),
    },
    {
      title: 'description',
      dataIndex: 'description',
      sorter: false,
    },
    {
      title: 'price',
      sorter: false,
      render: (_, event) => (
        <CustomInput
          onChangeZero = {event.name === 'close_of_escrow'}
          formItemProps={{
            className: 'm-0 label-gray',
            name: ['loans', loanKey, `${event.name}_price`],
          }}
          identifier={['loans', loanKey, `${event.name}_price`]}
          bordered={!isBorrower && !event.disabled}
          disabled={isBorrower || event.disabled}
          prefix="$"
          value={loan && event.prepareData && event.prepareData(loan)}
          onBlur={() => handleLoansUpdate?.()}
        />
      ),
    },
  ];

  return (
    <Table<IEvent>
      search={false}
      columns={columns}
      showSorterTooltip={false}
      toolBarRender={false}
      dataSource={events.sort((a, b) => a.key - b.key)}
      pagination={false}
    />
  );
};

export default ClosingCostTable;
