import Layout from '../../../components/Layout/Auth';
import Wrapper from '../../../components/Pages/Auth';
import Content from '../../../components/Pages/Auth/ResetPassword';
import { displayName } from '../../../config';

const ResetPassword = (): JSX.Element => {
  document.title = `${displayName}: Reset Password`;

  return (
    <Layout>
      <Wrapper title="Reset Password" text="Please enter new password.">
        <Content />
      </Wrapper>
    </Layout>
  );
};

export default ResetPassword;
