import { Layout } from 'antd';

interface Simple {
  children: React.ReactNode;
}

export default function Simple({ children }: Simple): JSX.Element {
  const { Content } = Layout;

  return (
    <Layout style={{ backgroundColor: 'var(--color-page-background)', height: '100%' }}>
      <Content>{children}</Content>
    </Layout>
  );
}
