import { Image, Col, Row, Typography, Checkbox, Form } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useAuth } from '../../../../../context/auth';
import { useContextUsers } from '../../../../../context/users';
import { useProfileUpdate } from '../../../../../hooks/users';
import ContentCard from '../../../../Common/ContentCard';
import Section from '../Section';

import styles from './index.module.less';

const ComplianceLogos = () => {
  const form = useFormInstance();
  const { profileIdLoading } = useContextUsers();
  const activeLogo = useWatch('settings_logo', form) || 'ComplianceLogoType1.png';
  const profileUpdate = useProfileUpdate();

  const logos = Array.from({ length: 5 }, (__, i) => `ComplianceLogoType${i + 1}.png`);

  const {
    user: { id, email },
  } = useAuth();
  const handleLogoChange = (logo: string) => {
    if (!id) return;

    form.setFieldValue('settings_logo', logo);
    form.validateFields().then((values) => {
      profileUpdate.fetch({ email, settings_logo: values.settings_logo }, id);
    });
  };

  return (
    <Section
      form={form}
      label="Compliance Logos"
      description="Include the appropriate logo on your marketing materials per your state's laws."
      cardLoading={profileIdLoading}
    >
      <div className="flex-col gap-20">
        <Typography className="fw-600">Compliance Logos*</Typography>
        <Form.Item name="settings_logo" noStyle />
        <Row gutter={[20, 20]}>
          {logos.map((logo) => (
            <Col key={logo} span={24} lg={12}>
              <ContentCard bordered padding={20}>
                <Checkbox
                  className={styles.checkboxWrapper}
                  checked={activeLogo === logo}
                  onChange={() => handleLogoChange(logo)}
                >
                  <Image preview={false} style={{ objectFit: 'contain' }} height={100} src={`/${logo}`} />
                </Checkbox>
              </ContentCard>
            </Col>
          ))}
        </Row>
      </div>
    </Section>
  );
};

export default ComplianceLogos;
