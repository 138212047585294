import React, { useState, useCallback } from 'react';
import { Button, Checkbox, Col, Input, Popover, Tag, Typography, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import Form from 'antd/es/form';
import { useWatch } from 'antd/es/form/Form';
import { FormListFieldData } from 'antd/es/form/FormList';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import clsx from 'clsx';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import moment from 'moment-timezone';
import { DraggableProvided } from 'react-beautiful-dnd';
import debounce from "just-debounce-it";
import { useContextNotifications } from '../../../../../../../context/notifications';
import { useContextReports } from '../../../../../../../context/reports';
import { EnumReportTypes, ILoanColumn, IReportRow } from '../../../../../../../types/reports';
import { getInputsCount } from '../../../../../../../utils/loan';
import CustomInput from '../../../../../../Common/CustomInput';
import { Delete, Drag, Duplicate, Note, Paint } from '../../../../../../Common/Icon';
import styles from '../../index.module.less';
import { getSuffixDetails } from '../../../../constants';

interface ITableCol {
  field: FormListFieldData;
  provided?: DraggableProvided;
  isDragging: boolean;
  disabled: boolean;
  handleRemove: () => void;
  itemKey: number;
  onCheckAddUipToggle: (e: CheckboxChangeEvent, id: string | number) => void;
  onCheckboxRecalculate: (e: CheckboxChangeEvent, id: string, zpid: string) => void;
  onCheckboxToggle: (e: CheckboxChangeEvent, id: string) => void;
  onCheckboxInterestOnlyToggle: (e: CheckboxChangeEvent, id: string) => void;
  isFirst: boolean;
  isRefinance: boolean;
  reportType: EnumReportTypes | undefined;
}

const filterLoansWithSegment = (loans: ILoanColumn[]) => loans?.filter((loan: ILoanColumn) => !loan?.isSegment);
// 4. Make mortgage insurance to have selection with options - 0.1, 0.15, 0.2, 0.25, 0.3, 0.55 
const TableCol = ({
  itemKey,
  field,
  provided,
  isDragging,
  disabled,
  handleRemove,
  onCheckboxToggle,
  onCheckboxRecalculate,
  onCheckAddUipToggle,
  onCheckboxInterestOnlyToggle,
  isFirst,
  isRefinance,
  reportType
}: ITableCol) => {
  const { openConfirm } = useContextNotifications();
  const { isBorrower, loans: reportLoans, handleLoansUpdate } = useContextReports();
  const colorBlue = getComputedStyle(document.documentElement).getPropertyValue('--color-blue');
  const highlightColor = getComputedStyle(document.documentElement).getPropertyValue('--color-highlight-yellow');
  const [editingName, setEditingName] = useState<Record<number, boolean>>({});
  const [editingAddress, setEditingAddress] = useState<Record<number, boolean>>({});

  const colColorChange = (name: number) => {
    const color = form.getFieldValue(['loans', name, 'color']);

    form.setFieldValue(['loans', name, 'color'], color ? null : highlightColor);
    handleLoansUpdate?.();
  };

  const fieldColorChange = (index: number, name: string) => {
    const colors = form.getFieldValue(['loans', index, 'loanColor']);

    form.setFieldValue(['loans', index, 'loanColor'], {
      ...colors,
      [name]: colors?.[name] || colors?.[name] === '0' ? null : highlightColor,
    });

    handleLoansUpdate?.();
  };

  const form = useFormInstance();
  const loans = useWatch('loans', form);

  const handleClone = (key: number) => {
    const newLoans = [...loans];
    const originalLoan = { ...newLoans[key] };
  
    const newLoan = {
      ...originalLoan,
      name: originalLoan.name,
      isCloned: true,
    };
  
    delete newLoan.id;
    newLoans.push(newLoan);
  
    form.setFieldValue('loans', newLoans);
    debouncedUpdate();
  };
  
  const debouncedUpdate = useCallback(
    debounce(() => {
      handleLoansUpdate?.({}, true);
    }, 1250),
    []
  );

  const handleNameEditToggle = (index: number) => {
    setEditingName((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleAddressEditToggle = (index: number) => {
    setEditingAddress((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleAddressUpdate = (index: number) => {
    handleAddressEditToggle(index);
    handleLoansUpdate?.();
  };

  const handleNameUpdate = (index: number) => {
    handleNameEditToggle(index);
    handleLoansUpdate?.();
  };

  const updatePopoverNote = (key: number, name: string, value: string) => {
    form.setFieldValue(['loans', key, name], value);
    handleLoansUpdate?.();
  };

  const tooltipStyle = {
    backgroundColor: '#f5f5f5',
    fontSize: '12px',
  };

  return (
    <Col
      className={clsx(styles.tableCol, { [styles.master]: field.key === 0 }, styles.width250)}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
    >
      <Form.Item shouldUpdate>
        {() => {
          const tableView: IReportRow[] = form.getFieldValue('tableView');
          const col = form.getFieldValue(['loans', field.name]);
          const isFieldDisabled = col?.type !== 'borrower' && isBorrower;
          let name:string

          if (isRefinance && isFirst) {
            name = 'Current Loan';
          } else if (isRefinance) {
            const defaultName = `Refi Option ${field.name + 1}`;
            
            name = form.getFieldValue(['loans', field.name, 'name']) || defaultName;
          } else {
            const defaultName = `Option ${field.name + 1}`;

            name = form.getFieldValue(['loans', field.name, 'name']) || defaultName;
          }

          const address = form.getFieldValue(['loans', field.name, 'address'])?.toUpperCase();
          let delimiter = '';

          if (address?.includes(';')) {
            delimiter = ';';
          } else if (address?.includes(',')) {
            delimiter = ',';
          }
          const address1 = delimiter ? address?.split(delimiter)[0] : address;
          const address2 = delimiter ? address?.replace(`${address1}${delimiter}`, '') : null;

          let isDragPopoverVisable = true;

          if(isFieldDisabled){
            isDragPopoverVisable = false;
          }
          else if(isFirst && isRefinance){
            isDragPopoverVisable = false
          }

          return (
            <>
              <div className={styles.tableHeadCell} style={{ backgroundColor: '#F5F5F9' }}>
                <div className={styles.headerRow}>
                  {!editingName[field.name] ? (
                    <Tooltip placement="top" overlayStyle={tooltipStyle} title={name.toUpperCase()}>
                      <Typography.Title
                        level={4}
                        className={clsx(styles.title, 'color-gray8', {
                          [styles.disabled]: disabled,
                        })}
                        onClick={() => !isFieldDisabled && handleNameEditToggle(field.name)}
                      >
                        {name}
                      </Typography.Title>
                    </Tooltip>
                  ) : (
                    <Form.Item {...field} name={[field.name, 'name']} className="table-form-item">
                      <Input autoFocus onBlur={() => handleNameUpdate(field.name)} placeholder="Enter text" />
                    </Form.Item>
                  )}

                  {!disabled && (
                    <>
                      <div className={styles.headActions}>
                        {!isFieldDisabled && (
                          <Popover content="Highlight column">
                            <Icon
                              component={Paint}
                              className={styles.icon}
                              onClick={() => colColorChange(field.name)}
                            />
                          </Popover>
                        )}
                        <Popover
                          content={
                            <div className={styles.popperContent}>
                              <Typography.Title level={4}>Remove location</Typography.Title>
                              <Typography className={styles.popperText}>
                                To change your location, click here and select a new location
                              </Typography>
                            </div>
                          }
                          trigger={isDragging ? '' : 'hover'}
                        >
                          {!isFieldDisabled && (
                            <Icon
                              className={styles.icon}
                              component={Delete}
                              onClick={() =>
                                openConfirm?.({
                                  title: 'Remove location',
                                  content: 'Are you sure you want to remove the location?',
                                  okText: 'Yes, remove',
                                  onOk: (handleClose) => {
                                    handleRemove();
                                    handleLoansUpdate?.(undefined, true);
                                    handleClose();
                                  },
                                })
                              }
                            />
                          )}
                        </Popover>
                        <Popover content="Click to clone location" trigger={isDragging ? '' : 'hover'}>
                          <Icon className={styles.icon} component={Duplicate} onClick={() => handleClone(itemKey)} />
                        </Popover>
                      </div>
                      {isDragPopoverVisable && (
                        <Popover
                          content={
                            <div className={styles.popperContent}>
                              <Typography.Title level={4}>Drag to move</Typography.Title>
                              <Typography className={styles.popperText}>
                                To make a column a master column, drag it to the first place
                              </Typography>
                            </div>
                          }
                          trigger={isDragging ? '' : 'hover'}
                        >
                          <Icon className={styles.icon} component={Drag} {...provided?.dragHandleProps} />
                        </Popover>
                      )}
                    </>
                  )}
                </div>
                {!editingAddress[field.name] ? (
                  <>
                    <Typography
                      style={{ cursor: 'pointer', whiteSpace: 'normal', fontSize: '0.825rem' }}
                      className={styles.location}
                      onClick={() => !isFieldDisabled && handleAddressEditToggle(field.name)}
                    >
                      {address1 ? `${address1}` : !isFieldDisabled && '(No address)'}
                    </Typography>
                    <Typography
                      style={{ cursor: 'pointer', whiteSpace: 'normal', fontSize: '0.825rem'  }}
                      className={styles.location}
                      onClick={() => !isFieldDisabled && handleAddressEditToggle(field.name)}
                    > 
                      {address2 || (!isFieldDisabled && '')}
                    </Typography>
                  </>
                ) : (
                  <Form.Item {...field} name={[field.name, 'address']} className="table-form-item">
                    <Input autoFocus onBlur={() => handleAddressUpdate(field.name)} placeholder="Enter text" />
                  </Form.Item>
                )}
                {col?.type === 'borrower' && (
                  <div className={styles.added}>
                    <Popover
                      content={
                        <div className={styles.popperContent}>
                          <Typography className={styles.popperText}>
                            This column was added on {moment(col.createdAt).format('dddd, MM/DD/YYYY [at] hh:mm A')}
                          </Typography>
                        </div>
                      }
                      trigger={isDragging ? '' : 'hover'}
                    >
                      <Tag color={colorBlue} className="m-0 capitalize cursor-default">
                        Added
                      </Tag>{' '}
                    </Popover>
                  </div>
                )}
              </div>
              <Form.Item noStyle name={['loans', field.name, 'id']} />
              {tableView &&
                Object.values(tableView)
                  .filter((row) => row.active)
                  .sort((a, b) => a.order - b.order)
                  .map((row) => {
                    const loan = form.getFieldValue(['loans', field.name]);
                    const item = form.getFieldValue(['loans', field.name, row.name]);
                    const inputsCount = getInputsCount(tableView);
                    let rowStyle = { ...row.styles };

                    if (row.name === 'total' && !loan.isSegment) {
                      rowStyle = { ...rowStyle, border: '1px solid #dedede' };
                    }

                    if (row.color || loan.color || loan.loanColor?.[row.name]) {
                      rowStyle = {
                        ...rowStyle,
                        background: loan.loanColor?.[row.name] || row.color || loan.color,
                      };
                    }

                    const { suffix, suffixContent } = getSuffixDetails(row, loan);

                    return (
                      <div key={`${field.key}-${row.name}`} className={styles.tableCell} style={rowStyle}>
                        {row.subName && <Form.Item noStyle name={['loans', field.name, row.subName]} />}
                        <div>
                          {row.name === 'loanDetails' ? (
                            <div
                              style={{
                                minHeight: '60px',
                                maxHeight: '65px',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <Tooltip placement="top" overlayStyle={tooltipStyle} title={name.toUpperCase()}>
                                <Typography.Title
                                  level={4}
                                  style={{
                                    marginBottom: '10px',
                                    color: '#8D94A2',
                                    fontWeight: '400',
                                  }}
                                >
                                  {name.toUpperCase()}
                                </Typography.Title>
                              </Tooltip>
                              <Typography
                                style={{ cursor: 'pointer', whiteSpace: 'normal', fontSize: '12px', color: '#8D94A2' }}
                                className={styles.location}
                                onClick={() => !isFieldDisabled && handleAddressEditToggle(field.name)}
                              >
                                {address?.replace(';', ' ,') || (!isFieldDisabled && '(No address)')}
                              </Typography>
                            </div>
                          ) : (
                            <CustomInput
                              onChangeZero
                              withEclipses
                              formItemProps={{
                                ...field,
                                className: 'w-100 table-form-item',
                                name: [field.name, row.name, 'value'],
                              }}
                              suffixContent={suffixContent}
                              tabIndex={row?.tabIndex ? row.tabIndex + field.name * inputsCount : undefined}
                              value={row.prepareData?.(loan, loans)}
                              identifier={['loans', field.name, row.name, 'value']}
                              prefix={row.prefix}
                              suffix={suffix}
                              disabled={row.disabled?.(loan) || disabled || isFieldDisabled}
                              onChange={(value) => row.onChange?.(form, itemKey, value)}
                              placeholder={row.placeholder || '0'}
                              inputClassName={clsx({ [styles.bolded]: row.bolded })}
                              options={!isFieldDisabled && row.options ? row.options() : undefined}
                              onSelect={(value) => row.onSelect?.(form, itemKey, value)}
                              activeOption={item?.value}
                              showArrow={!!row.options}
                              additionalText={row.additionalText?.(loan, filterLoansWithSegment(loans), reportType)}
                              rowStyles={row.subField ? { minHeight: 0 } : undefined}
                              onBlur={(value) => {
                                const oldValue = reportLoans?.[field.name]?.[row.name];

                                if (value !== oldValue && handleLoansUpdate) {
                                  handleLoansUpdate();
                                }
                              }}
                              popoverContent={row.getPopoverContent?.(field, inputsCount)}
                            />
                          )}
                          {row.subField &&
                            (row.subField.name !== 'loanAmountWithUip' ||
                              (row.subField.name === 'loanAmountWithUip' && loan.addUip)) && (
                              <CustomInput
                                onChangeZero
                                withEclipses
                                formItemProps={{
                                  ...field,
                                  className: 'w-100 table-form-item',
                                  name: [field.name, row.subField.name, 'value'],
                                }}
                                tabIndex={
                                  row.subField.tabIndex ? row.subField.tabIndex + field.name * inputsCount : undefined
                                }
                                value={row.subField.prepareData?.(loan, loans)}
                                identifier={['loans', field.name, row.subField.name, 'value']}
                                prefix={row.subField.prefix}
                                suffix={row.subField.suffix}
                                disabled={row.subField.disabled?.(loan) || disabled || isFieldDisabled}
                                onChange={(value) => row.subField?.onChange?.(form, itemKey, value)}
                                placeholder={row.subField.placeholder || '0'}
                                inputClassName={clsx({ [styles.bolded]: row.bolded })}
                                options={!isFieldDisabled && row.subField.options ? row.subField.options() : undefined}
                                showArrow={!!row.subField.options}
                                // TODO: Pass report type here
                                additionalText={row.subField.additionalText?.(loan, loans, reportType)}
                                rowStyles={{ fontSize: '0.75rem', minHeight: 0, color: 'var(--color-gray)' }}
                                onBlur={(value) => {
                                  const oldValue = reportLoans?.[field.name]?.[row.name];

                                  if (value !== oldValue && handleLoansUpdate) {
                                    handleLoansUpdate();
                                  }
                                }}
                              />
                            )}
                        </div>
                        {!disabled && !isFieldDisabled && (
                          <div
                            className={styles.actions}
                            style={
                              row.color || loan.color || loan.loanColor?.[row.name]
                                ? { background: loan.loanColor?.[row.name] || row.color || loan.color }
                                : undefined
                            }
                          >
                            <div className={styles.list}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  gap: '10px',
                                }}
                              >
                                {row.name === 'total' && (
                                  <Popover content="Remove from high/low calculation">
                                    <Checkbox
                                      onChange={(e) => onCheckboxToggle(e, loan.id)}
                                      defaultChecked={loan?.isSegment || false}
                                    />
                                  </Popover>
                                )}
                                {row.name === 'principalInterest' && (
                                  <Popover content="Change to interest only">
                                    <Checkbox
                                      onChange={(e) => onCheckboxInterestOnlyToggle(e, loan.id)}
                                      defaultChecked={loan?.interestOnly || false}
                                    />
                                  </Popover>
                                )}
                                {row.name === 'propertyTaxes' && loan.zpid && (
                                  <Popover content="Recalculate property taxes based on a refinance.">
                                    <Checkbox
                                      onChange={(e) => onCheckboxRecalculate(e, loan.id, loan.zpid)}
                                      defaultChecked={loan.recalculateTax || false}
                                    />
                                  </Popover>
                                )}
                                {row.name === 'uip' && (
                                  <Popover content="Add to loan amount.">
                                    <Checkbox
                                      onChange={(e) => onCheckAddUipToggle(e, loan.id)}
                                      defaultChecked={loan.addUip || false}
                                    />
                                  </Popover>
                                )}
                                {!isBorrower && row.note && row.name !== 'total' && (
                                  <Popover
                                    content={
                                      <div style={{ maxWidth: 230 }}>
                                        <Typography.Text>Note for {row.text?.toLowerCase()} field</Typography.Text>
                                        <Input
                                          autoFocus
                                          defaultValue={loan[row.note] === '0' ? '' : loan[row.note]}
                                          onBlur={(e) => updatePopoverNote(itemKey, row.note || '', e.target.value)}
                                        />
                                      </div>
                                    }
                                    trigger="click"
                                  >
                                    <Tooltip title="Add Note" overlayStyle={tooltipStyle}>
                                      <Button type="ghost" icon={<Icon component={Note} className={styles.icon} />} />
                                    </Tooltip>
                                  </Popover>
                                )}
                                <Popover content="Highlight cell">
                                  <Button
                                    type="ghost"
                                    icon={<Icon component={Paint} className={styles.icon} />}
                                    onClick={() => fieldColorChange(field.name, row.name)}
                                  />
                                </Popover>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
            </>
          );
        }}
      </Form.Item>
    </Col>
  );
};

TableCol.defaultProps = {
  provided: undefined,
};

export default TableCol;
