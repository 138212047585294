import { Col, Divider, Image, Row, Typography } from 'antd';
import clsx from 'clsx';
import { useContextReports } from '../../../../../context/reports';
import { simplifyEmbedCode } from '../Video';

import styles from '../../index.module.less';
import './header.less';

const Header = () => {
  const { report } = useContextReports();

  return (
    <>
      <div className="wrapper">
        <Row gutter={[20, 20]} style={{ width: '100%' }}>
          <div className="p-inline-10 flex-row gap-8 flex-align-center">
            <div className={clsx(styles.imageWrapper, 'd-m-none')}>
              <Image
                preview={false}
                src={report?.User?.avatar || '/avatar-placeholder.png'}
                alt="User Avatar"
                height={120}
                width={120}
              />
            </div>
            <div className={clsx(styles.imageWrapper, 'd-m-flex d-lg-none')}>
              <Image
                preview={false}
                src={report?.User?.avatar || '/avatar-placeholder.png'}
                alt="User Avatar"
                height={52}
                width={52}
              />
            </div>
            <Typography.Title level={2} className="d-m-flex d-lg-none">
              {report?.User?.firstName} {report?.User?.lastName}
            </Typography.Title>
          </div>
          <Col style={{ marginRight: '80px' }} md={12} span={24}>
            <div className="flex-col gap-20">
              <div className="d-m-none">
                <Typography.Title level={2}>
                  {report?.User?.firstName} {report?.User?.lastName}
                </Typography.Title>
              </div>
              <Row gutter={[20, 20]}>
                <Col md={12} span={24}>
                  <div className="flex-col gap-8">
                    <Typography.Title level={4} className="color-gray uppercase">
                      Phone
                    </Typography.Title>
                    <Typography.Text className="fs-1">{report?.User?.phoneNumber || '-'}</Typography.Text>
                  </div>
                </Col>
                <Col md={12} span={24}>
                  <div className="flex-col gap-8">
                    <Typography.Title level={4} className="color-gray uppercase">
                      Email
                    </Typography.Title>
                    <Typography.Text style={{ whiteSpace: 'nowrap' }} className="fs-1">
                      {report?.User?.email || '-'}
                    </Typography.Text>{' '}
                  </div>
                </Col>
              </Row>
              <Row gutter={[20, 20]}>
                <Col md={12} span={24}>
                  <div className="flex-col gap-8">
                    <Typography.Title level={4} className="color-gray uppercase">
                      Address
                    </Typography.Title>
                    <Typography.Text className="fs-1">{report?.User?.address || '-'}</Typography.Text>{' '}
                  </div>
                </Col>
                <Col md={12} span={24}>
                  <div className="flex-col gap-8">
                    <Typography.Title level={4} className="color-gray uppercase">
                      NMLS License
                    </Typography.Title>
                    <Typography.Text className="fs-1">{report?.User?.nmlsId || '-'}</Typography.Text>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <div>
            <Image
              preview={false}
              style={{ objectFit: 'contain' }}
              height={60}
              src={`/${report?.User?.settings_logo || 'ComplianceLogoType1.png'}`}
            />
          </div>
        </Row>
        <div
          className="videoEmbed"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: simplifyEmbedCode(report?.video || '') }}
        />
      </div>
      <Divider />
    </>
  );
};

export default Header;
