import Icon from '@ant-design/icons';
import { Form as AntdForm, Switch, Typography } from 'antd';
import clsx from 'clsx';
import { NamePath } from 'rc-field-form/lib/interface';
import React, { CSSProperties } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Drag } from '../Icon';

import styles from './index.module.less';

interface ISettingsListItem extends CSSProperties {
  name: NamePath;
  provided: DraggableProvided;
  text: string;
  draggable?: boolean;
  onChange?: () => void;
}

const DraggableListItem = ({ name, provided, text, draggable, onChange, ...style }: ISettingsListItem) => (
  <div className={styles.item} ref={provided.innerRef} {...provided.draggableProps}>
    <div className={clsx(styles.row, { [styles.draggable]: draggable })} style={style}>
      {draggable && <Icon className={styles.icon} component={Drag} {...provided.dragHandleProps} />}
      <div className={styles.content}>
        <Typography className={styles.label}>{text}</Typography>
        <AntdForm.Item name={name} label="test" valuePropName="checked" noStyle>
          <Switch onChange={onChange} />
        </AntdForm.Item>
      </div>
    </div>
  </div>
);

DraggableListItem.defaultProps = {
  draggable: false,
  onChange: undefined,
};

export default DraggableListItem;
