export const capitalize = (str: string) =>
  str
    .split(' ')
    .map((string) => string[0].toUpperCase() + string.slice(1, string.length))
    .join(' ');

export const copyTextToClipboard = (text: string) =>
  'clipboard' in navigator
    ? navigator.clipboard.writeText(text)
    : new Promise(() => {
        document.execCommand('copy', true, text);
      });

export const commafy = (text?: string | number, digits?: number) => {
  if (text === undefined) return '';

  const str = text?.toString().replace(/\s/g, '').replace(/,/g, '').split('.');

  if (str?.[0]?.length >= 4) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }

  const fractionDigits = digits === undefined ? 0 : digits;

  if (fractionDigits && str[1]) str[1] = str[1]?.slice(0, fractionDigits);

  return !fractionDigits ? str?.[0] : str.join('.');
};

export const toFloat = (text?: string | number) => {
  if (!text) return 0;

  const str = text?.toString().replace(/,/g, '');

  return parseFloat(str);
};

export const getThousands = (price?: number) => {
  if (!price) return 0;

  return `${commafy(price / 1000)}k`;
};

export const toFixed = (value: string | number, count: number) => {
  const str = `${value}`.split('.');

  str[1] = str[1]?.slice(0, count).replace(/0*$/, '');

  return str[1]?.length ? str.join('.') : str[0];
};

export const getNumbers = (str: string) => {
  const [whole, fractional] = str.includes('.') ? str.split('.') : str.split(',');

  const preparedWhole = whole.replace(/[^0-9]/g, '');

  return fractional?.length ? [preparedWhole, fractional].join('.') : preparedWhole;
};
