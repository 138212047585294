import Layout from '../../../components/Layout/Main';
import Content from '../../../components/Pages/Reports/Edit';
import { displayName } from '../../../config';
import ReportsProvider from '../../../context/reports';

const ReportEdit = (): JSX.Element => {
  document.title = `${displayName}: Report Edit`;

  return (
    <Layout>
      <ReportsProvider value={{ isBorrower: false }}>
        <Content isLoading={false} />
      </ReportsProvider>
    </Layout>
  );
};

export default ReportEdit;
