import Icon from '@ant-design/icons';
import { Button, Col, Divider, Popover, Row } from 'antd';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { IActivity, IReport } from '../../../../../hooks/reports';
import { Back, Comment, Edit, Eye, Forward, Quote } from '../../../../Common/Icon';
import Tag from '../Tag';
import styles from '../index.module.less';

interface IReportRow {
  report: IReport;
  isLast?: boolean;
}

const ReportRow = ({ report, isLast }: IReportRow) => {
  const rowRef = useRef<HTMLDivElement | null>(null);
  const [isScrollNeeded, setIsScrollNeeded] = useState<boolean>(false);
  const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState<boolean>(true);
  const [isRightArrowDisabled, setIsRightArrowDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (!rowRef.current) return;

    setIsScrollNeeded(rowRef.current.scrollWidth > rowRef.current?.clientWidth);
  }, [rowRef]);

  const handleScroll = () => {
    if (!rowRef.current) return;

    if (rowRef.current.scrollLeft === 0 && !isLeftArrowDisabled) {
      setIsLeftArrowDisabled(true);
    }

    if (rowRef.current.scrollLeft > 0 && isLeftArrowDisabled) {
      setIsLeftArrowDisabled(false);
    }

    if (
      rowRef.current.scrollWidth === rowRef.current.clientWidth + rowRef.current.scrollLeft &&
      !isRightArrowDisabled
    ) {
      setIsRightArrowDisabled(true);
    }

    if (rowRef.current.scrollWidth > rowRef.current.clientWidth + rowRef.current.scrollLeft && isRightArrowDisabled) {
      setIsRightArrowDisabled(false);
    }
  };

  const handleScrollLeft = () => {
    if (!rowRef.current) return;

    const potentialScroll = rowRef.current.scrollLeft - rowRef.current.clientWidth;

    const newScroll = potentialScroll < 0 ? 0 : potentialScroll;

    rowRef.current?.scrollTo({ left: newScroll, behavior: 'smooth' });
  };

  const handleScrollRight = () => {
    if (!rowRef.current) return;

    const potentialScroll = rowRef.current.scrollLeft + rowRef.current.clientWidth;

    const newScroll =
      potentialScroll > rowRef.current.scrollWidth
        ? rowRef.current.scrollWidth - rowRef.current.clientWidth
        : potentialScroll;

    rowRef.current?.scrollTo({ left: newScroll, behavior: 'smooth' });
  };

  const getActivity = (activity: IActivity) => {
    switch (activity.type) {
      case 'viewed':
        return <Tag label="Viewed" icon={Eye} date={activity.date} />;

      case 'edited':
        return <Tag label="Edited" icon={Edit} date={activity.date} />;

      case 'hoiquote':
        return <Tag label="Ordered HOI Quote" icon={Quote} date={activity.date} url={activity.url} />;

      default:
        return <Tag label="Created" icon={Comment} date={activity.date} />;
    }
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={6} className="overflow-ellipsis cursor-default">
          <Popover content={report.name}>{report.name}</Popover>
        </Col>
        <Col span={18}>
          <div className={styles.activitiesList}>
            {isScrollNeeded && (
              <div className={clsx(styles.arrowWrapper, styles.arrowLeft)}>
                <Button
                  className={styles.arrow}
                  type="ghost"
                  icon={<Icon component={Back} />}
                  onClick={handleScrollLeft}
                  disabled={isLeftArrowDisabled}
                />
              </div>
            )}
            {isScrollNeeded && (
              <div className={clsx(styles.arrowWrapper, styles.arrowRight)}>
                <Button
                  className={styles.arrow}
                  type="ghost"
                  icon={<Icon component={Forward} />}
                  onClick={handleScrollRight}
                  disabled={isRightArrowDisabled}
                />
              </div>
            )}
            <div className="overflow-auto flex-row gap-12" ref={rowRef} onScroll={handleScroll}>
              {!!report.Activities?.length &&
                report.Activities.map((activity) => <div key={activity.id}>{getActivity(activity)}</div>)}
            </div>
          </div>
        </Col>
      </Row>
      {!isLast && <Divider />}
    </>
  );
};

ReportRow.defaultProps = {
  isLast: false,
};

export default ReportRow;
