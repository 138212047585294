import { FILING_STATUS_ENUM } from './components/Pages/Reports/constants'

interface StateOption {
    text: string;
    value: string;
};

export interface ITaxBracket {
    value: number;
    text: string;
}

export interface IStateFilingStatus {
    [FILING_STATUS_ENUM.SINGLE]?: ITaxBracket[];
    [FILING_STATUS_ENUM.FILLING_SEPARATELY]?: ITaxBracket[];
    [FILING_STATUS_ENUM.HEAD]?: ITaxBracket[];
    [FILING_STATUS_ENUM.FILLING_JOINTLY]?: ITaxBracket[];
}

export interface IStateNoIncomeTax {
    value: number;
    text: string;
}

export interface IStateTaxRates {
    [key: string]: IStateFilingStatus | IStateNoIncomeTax[];
}

export const STATE_TAX_RATE: IStateTaxRates = {
    AL: { // Alabama
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 2, text: '$0.00 to $500' },
            { value: 4, text: '$500 to $2500' },
            { value: 5, text: '$3000 and above' }
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 2, text: '$0.00 to $500' },
            { value: 4, text: '$500 to $2500' },
            { value: 5, text: '$3000 and above' }
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 2, text: '$0.00 to $500' },
            { value: 4, text: '$500 to $2500' },
            { value: 5, text: '$3000 and above' }
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 2, text: 'First $1,000 of taxable income' },
            { value: 4, text: 'Next $5,000 of taxable income' },
            { value: 5, text: 'All taxable income over $6,000' }
        ],
    },
    AK: [{ // Alaska
        value: 0,
        text: 'Does not have state income tax'
    }],
    AZ: [{ // Arizona
        value: 2.5,
        text: 'Flat rate'
    }],
    AR: [], // ! Dont know here what to do Arkansas
    CA: { // California
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 1, text: '$0 to $10,412' },
            { value: 2, text: '$10,413 to $24,684' },
            { value: 4, text: '$24,685 to $38,959' },
            { value: 6, text: '$38,960 to $54,081' },
            { value: 8, text: '$54,082 to $68,350' },
            { value: 9.3, text: '$68,351 to $349,137' },
            { value: 10.3, text: '$349,138 to $418,961' },
            { value: 11.3, text: '$418,962 to $698,271' },
            { value: 12.3, text: '$698,272 and above' },
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 1, text: '$0 to $20,824' },
            { value: 2, text: '$20,825 to $49,368' },
            { value: 4, text: '$49,369 to $77,918' },
            { value: 6, text: '$77,919 to $108,162' },
            { value: 8, text: '$108,163 to $136,700' },
            { value: 9.3, text: '$136,701 to $698,274' },
            { value: 10.3, text: '$698,275 to $837,922' },
            { value: 11.3, text: '$837,923 to $1,396,542' },
            { value: 12.3, text: '$1,369,543 or more' },
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 1, text: '$0 to $20,839' },
            { value: 2, text: '$20,840 to $49,371' },
            { value: 4, text: '$49,372 to $63,644' },
            { value: 6, text: '$63,645 to $78,765' },
            { value: 8, text: '$78,766 to $93,037' },
            { value: 9.3, text: '$93,038 to $474,824' },
            { value: 10.3, text: '$474,825 to $569,790' },
            { value: 11.3, text: '$569,791 to $949,649' },
            { value: 12.3, text: '$949,650 or more.' },
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 1, text: '$0 to $10,412' },
            { value: 2, text: '$10,413 to $24,684' },
            { value: 4, text: '$24,685 to $38,959' },
            { value: 6, text: '$38,960 to $54,081' },
            { value: 8, text: '$54,082 to $68,350' },
            { value: 9.3, text: '$68,351 to $349,137' },
            { value: 10.3, text: '$349,138 to $418,961' },
            { value: 11.3, text: '$418,962 to $698,271' },
            { value: 12.3, text: '$698,272 and above' },
        ],
    },
    CO: [{ // Colorado
        value: 2.5,
        text: 'Flat rate'
    }],
    CT: [], // ! Connecticut todo
    DE: { // Delaware
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 2, text: '$0 - $10,000' },
            { value: 4.5, text: '$10,001 - $50,000' },
            { value: 5, text: '$50,001 - $100,000' },
            { value: 5.5, text: '$100,001 - $200,000' },
            { value: 6, text: '$200,001 - $250,000' },
            { value: 6.5, text: '$250,001 - $500,000' },
            { value: 6.99, text: 'Over $500,000' },
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 2, text: '$0 - $20,000' },
            { value: 4.5, text: '$20,001 - $100,000' },
            { value: 5, text: '$100,001 - $200,000' },
            { value: 5.5, text: '$200,001 - $400,000' },
            { value: 6, text: '$400,001 - $500,000' },
            { value: 6.5, text: '$500,001 - $1,000,000' },
            { value: 6.99, text: 'Over $1,000,000' },
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 2, text: '$0 - $16,000' },
            { value: 4.5, text: '$16,001 - $64,500' },
            { value: 5, text: '$64,501 - $100,000' },
            { value: 5.5, text: '$100,001 - $200,000' },
            { value: 6, text: '$200,001 - $500,000' },
            { value: 6.5, text: '$500,001 - $800,000' },
            { value: 6.99, text: 'Over $800,000' },
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 2, text: '$0 - $10,000' },
            { value: 4.5, text: '$10,001 - $50,000' },
            { value: 5, text: '$50,001 - $100,000' },
            { value: 5.5, text: '$100,001 - $200,000' },
            { value: 6, text: '$200,001 - $250,000' },
            { value: 6.5, text: '$250,001 - $500,000' },
            { value: 6.99, text: 'Over $500,000' },
        ],
    },
    DC: { //  District of Columbia
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 4, text: '$0 - $10,000' },
            { value: 6, text: '$10,001 - $40,000' },
            { value: 6.5, text: '$40,001 - $60,000' },
            { value: 8.5, text: "$60,001 - $250,000" },
            { value: 9.25, text: "$250,001 - $500,000" },
            { value: 9.75, text: "$500,001 - $1,000,000" },
            { value: 10.75, text: "$1,000,001 and above" }
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 4, text: '$0 - $10,000' },
            { value: 6, text: '$10,001 - $40,000' },
            { value: 6.5, text: '$40,001 - $60,000' },
            { value: 8.5, text: "$60,001 - $250,000" },
            { value: 9.25, text: "$250,001 - $500,000" },
            { value: 9.75, text: "$500,001 - $1,000,000" },
            { value: 10.75, text: "$1,000,001 and above" }
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 4, text: '$0 - $10,000' },
            { value: 6, text: '$10,001 - $40,000' },
            { value: 6.5, text: '$40,001 - $60,000' },
            { value: 8.5, text: "$60,001 - $250,000" },
            { value: 9.25, text: "$250,001 - $500,000" },
            { value: 9.75, text: "$500,001 - $1,000,000" },
            { value: 10.75, text: "$1,000,001 and above" }
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 4, text: '$0 - $10,000' },
            { value: 6, text: '$10,001 - $40,000' },
            { value: 6.5, text: '$40,001 - $60,000' },
            { value: 8.5, text: "$60,001 - $250,000" },
            { value: 9.25, text: "$250,001 - $500,000" },
            { value: 9.75, text: "$500,001 - $1,000,000" },
            { value: 10.75, text: "$1,000,001 and above" }
        ]
    },
    FL: [{ // Florida
        value: 0,
        text: "Does not have state income tax."
    }],
    GA: [{ // Georgia 
        value: 5.49,
        text: "Flat rate."
    }],
    HI: [], // ! Hawaii
    ID: [{ // Idaho 
        value: 5.8,
        text: "Flat rate."
    }],
    IL: [{ // Illinois
        value: 4.95,
        text: "Flat rate."
    }],
    IN: [{ // Indiana 
        value: 5.49,
        text: "Flat rate."
    }],
    IA: [],// ! Iowa todo
    KS: { // Kansas
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 5.2, text: "Income up to $23,000" },
            { value: 5.58, text: "Income over $23,000" }
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 5.2, text: "Income up to $23,000" },
            { value: 5.58, text: "Income over $23,000" }
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 5.2, text: "Income up to $23,000" },
            { value: 5.58, text: "Income over $23,000" }
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 5.2, text: "Income up to $23,000 / $46,000" },
            { value: 5.58, text: "Income over $23,000 / $46,000" }
        ]
    },
    KY: [{ // Kentucky
        value: 4,
        text: "Flat rate"
    }],
    LA: { // Louisiana
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 1.85, text: "$0 - $12,500" },
            { value: 3.5, text: "$12,500 - $50,000" },
            { value: 4.25, text: "$50,000 and above" }
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 1.85, text: "$0 - $12,500" },
            { value: 3.5, text: "$12,500 - $50,000" },
            { value: 4.25, text: "$50,000 and above" }
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 1.85, text: "$0 - $25,000" },
            { value: 3.5, text: "$25,000 - $100,000" },
            { value: 4.25, text: "$100,000 and above" }
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 1.85, text: "$0 - $25,000" },
            { value: 3.5, text: "$25,000 - $100,000" },
            { value: 4.25, text: "$100,000 and above" }
        ]
    },
    ME: { // Maine
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 5.8, text: "Up to $26,050" },
            { value: 6.75, text: "$26,050 - $61,600" },
            { value: 7.15, text: "Over $61,600" }
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 5.8, text: "Up to $26,050" },
            { value: 6.75, text: "$26,050 - $61,600" },
            { value: 7.15, text: "Over $61,600" }
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 5.8, text: "Up to $52,100" },
            { value: 6.75, text: "$52,100 - $123,250" },
            { value: 7.15, text: "Over $123,250" }
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 5.8, text: "Up to $39,050" },
            { value: 6.75, text: "$39,050 - $92,450" },
            { value: 7.15, text: "Over $92,450" }
        ]
    },
    MD: { // Maryland
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 2, text: "$0 - $1,000" },
            { value: 3, text: "$1,000 - $2,000" },
            { value: 4, text: "$2,000 - $3,000" },
            { value: 4.75, text: "$3,000 - $100,000" },
            { value: 5, text: "$100,000 - $125,000" },
            { value: 5.25, text: "$125,000 - $150,000" },
            { value: 5.5, text: "$150,000 - $250,000" },
            { value: 5.75, text: "Over $250,000" },
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 2, text: "$0 - $1,000" },
            { value: 3, text: "$1,000 - $2,000" },
            { value: 4, text: "$2,000 - $3,000" },
            { value: 4.75, text: "$3,000 - $150,000" },
            { value: 5, text: "$150,000 - $175,000" },
            { value: 5.25, text: "$175,000 - $225,000" },
            { value: 5.5, text: "$225,000 - $300,000" },
            { value: 5.75, text: "Over $300,000" },
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 2, text: "$0 - $1,000" },
            { value: 3, text: "$1,000 - $2,000" },
            { value: 4, text: "$2,000 - $3,000" },
            { value: 4.75, text: "$3,000 - $150,000" },
            { value: 5, text: "$150,000 - $175,000" },
            { value: 5.25, text: "$175,000 - $225,000" },
            { value: 5.5, text: "$225,000 - $300,000" },
            { value: 5.75, text: "Over $300,000" },
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 2, text: "$0 - $1,000" },
            { value: 3, text: "$1,000 - $2,000" },
            { value: 4, text: "$2,000 - $3,000" },
            { value: 4.75, text: "$3,000 - $100,000" },
            { value: 5, text: "$100,000 - $125,000" },
            { value: 5.25, text: "$125,000 - $150,000" },
            { value: 5.5, text: "$150,000 - $250,000" },
            { value: 5.75, text: "Over $250,000" },
        ]
    },
    MA: [ // Massachusetts
        { value: 5, text: "Up to $1,000,000" },
        { value: 9, text: "Over $1,000,000" }
    ],
    MI: [{ // Michigan
        value: 4.25,
        text: "Flat rate."
    }],
    MN: { // Minnesota
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 5.35, text: "Up to $30,070" },
            { value: 6.8, text: "$30,070 to $98,760" },
            { value: 7.85, text: "$98,760 to $183,340" },
            { value: 9.85, text: "Over $183,340" }
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 5.35, text: "Up to $43,950" },
            { value: 6.8, text: "$43,950 to $174,610" },
            { value: 7.85, text: "$174,610 to $304,970" },
            { value: 9.85, text: "Over $304,970" }
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 5.35, text: "Up to $37,010" },
            { value: 6.8, text: "$37,010 to $148,730" },
            { value: 7.85, text: "$148,730 to $243,720" },
            { value: 9.85, text: "Over $243,720" }
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 5.35, text: "Up to $21,975" },
            { value: 6.8, text: "$21,975 to $87,305" },
            { value: 7.85, text: "$87,305 to $152,485" },
            { value: 9.85, text: "Over $152,485" }
        ]
    },
    MS: [{ // Mississippi
        value: 4.7,
        text: "Flat rate."
    }],
    MO: { // Missouri
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 0, text: "$0 - $1,207" },
            { value: 2, text: "Over $1,207 - $2,414" },
            { value: 2.5, text: "Over $2,414 - $3,621" },
            { value: 3, text: "Over $3,621 - $4,828" },
            { value: 3.5, text: "Over $4,828 - $6,035" },
            { value: 4, text: "Over $6,035 - $7,242" },
            { value: 4.5, text: "Over $7,242 - $8,449" },
            { value: 4.95, text: "Over $8,449" }
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 0, text: "$0 - $1,207" },
            { value: 2, text: "Over $1,207 - $2,414" },
            { value: 2.5, text: "Over $2,414 - $3,621" },
            { value: 3, text: "Over $3,621 - $4,828" },
            { value: 3.5, text: "Over $4,828 - $6,035" },
            { value: 4, text: "Over $6,035 - $7,242" },
            { value: 4.5, text: "Over $7,242 - $8,449" },
            { value: 4.95, text: "Over $8,449" }
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 0, text: "$0 - $1,207" },
            { value: 2, text: "Over $1,207 - $2,414" },
            { value: 2.5, text: "Over $2,414 - $3,621" },
            { value: 3, text: "Over $3,621 - $4,828" },
            { value: 3.5, text: "Over $4,828 - $6,035" },
            { value: 4, text: "Over $6,035 - $7,242" },
            { value: 4.5, text: "Over $7,242 - $8,449" },
            { value: 4.95, text: "Over $8,449" }
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 0, text: "$0 - $1,207" },
            { value: 2, text: "Over $1,207 - $2,414" },
            { value: 2.5, text: "Over $2,414 - $3,621" },
            { value: 3, text: "Over $3,621 - $4,828" },
            { value: 3.5, text: "Over $4,828 - $6,035" },
            { value: 4, text: "Over $6,035 - $7,242" },
            { value: 4.5, text: "Over $7,242 - $8,449" },
            { value: 4.95, text: "Over $8,449" }
        ]
    },
    MT: [ // Montana
        { value: 4.7, text: "$0 - $20,500" },
        { value: 5.9, text: "Over $20,500" }
    ],
    NE: { // Nebraska
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 2.46, text: "$0 - $2,999" },
            { value: 3.51, text: "$3,000 - $17,999" },
            { value: 5.01, text: "$18,000 - $28,999" },
            { value: 5.84, text: "$29,000 and over" }
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 2.46, text: "$0 - $5,999" },
            { value: 3.51, text: "$6,000 - $35,999" },
            { value: 5.01, text: "$36,000 - $57,999" },
            { value: 5.84, text: "$58,000 and over" }
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 2.46, text: "$0 - $5,599" },
            { value: 3.51, text: "$5,600 - $28,799" },
            { value: 5.01, text: "$28,800 - $42,999" },
            { value: 5.84, text: "$43,000 and over" }
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 2.46, text: "$0 - $2,999" },
            { value: 3.51, text: "$3,000 - $17,999" },
            { value: 5.01, text: "$18,000 - $28,999" },
            { value: 5.84, text: "$29,000 and over" }
        ]
    },
    NV: [{   // Nevada
        value: 0,
        text: "Does not have state income tax."
    }],
    NH: [{  // New Hampshire
        value: 0,
        text: "Does not have state income tax."
    }],
    NJ: { // New Jersey
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 1.4, text: "$0 - $20,000" },
            { value: 1.75, text: "$20,001 - $35,000" },
            { value: 2.45, text: "N/A" },
            { value: 3.5, text: "$35,001 - $40,000" },
            { value: 5.53, text: "$40,001 - $75,000" },
            { value: 6.37, text: "$75,001 - $500,000" },
            { value: 8.97, text: "$500,001 - $1,000,000" },
            { value: 10.75, text: "Over $1,000,000" }
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 1.4, text: "$0 - $20,000" },
            { value: 1.75, text: "$20,001 - $35,000" },
            { value: 2.45, text: "N/A" },
            { value: 3.5, text: "$35,001 - $40,000" },
            { value: 5.53, text: "$40,001 - $75,000" },
            { value: 6.37, text: "$75,001 - $500,000" },
            { value: 8.97, text: "$500,001 - $1,000,000" },
            { value: 10.75, text: "Over $1,000,000" }
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 1.4, text: "$0 - $20,000" },
            { value: 1.75, text: "$20,001 - $50,000" },
            { value: 2.45, text: "$50,001 - $70,000" },
            { value: 3.5, text: "$70,001 - $80,000" },
            { value: 5.53, text: "$80,001 - $150,000" },
            { value: 6.37, text: "$150,001 - $500,000" },
            { value: 8.97, text: "$500,001 - $1,000,000" },
            { value: 10.75, text: "Over $1,000,000" }
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 1.4, text: "$0 - $20,000" },
            { value: 1.75, text: "$20,001 - $50,000" },
            { value: 2.45, text: "N/A" },
            { value: 3.5, text: "$50,001 - $70,000" },
            { value: 5.53, text: "$70,001 - $80,000" },
            { value: 6.37, text: "$80,001 - $150,000" },
            { value: 8.97, text: "$150,001 - $500,000" },
            { value: 10.75, text: "Over $500,000" }
        ]
    },
    NM: { // New Mexico
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 1.5, text: "$0 - $5,500" },
            { value: 3.2, text: "$5,500 - $11,000" },
            { value: 4.7, text: "$11,000 - $16,000" },
            { value: 4.9, text: "$16,000 - $210,000" },
            { value: 5.9, text: "Over $210,000" }
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 1.5, text: "$0 - $8,000" },
            { value: 3.2, text: "$8,000 - $16,000" },
            { value: 4.7, text: "$16,000 - $24,000" },
            { value: 4.9, text: "$24,000 - $315,000" },
            { value: 5.9, text: "Over $315,000" }
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 1.5, text: "$0 - $5,500" },
            { value: 3.2, text: "$5,500 - $11,000" },
            { value: 4.7, text: "$11,000 - $16,000" },
            { value: 4.9, text: "$16,000 - $210,000" },
            { value: 5.9, text: "Over $210,000" }
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 1.5, text: "$0 - $5,500" },
            { value: 3.2, text: "$5,500 - $11,000" },
            { value: 4.7, text: "$11,000 - $16,000" },
            { value: 4.9, text: "$16,000 - $210,000" },
            { value: 5.9, text: "Over $210,000" }
        ]
    },
    NY: { // New York
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 4, text: "$0 - $8,500" },
            { value: 4.5, text: "$8,501 - $11,700" },
            { value: 5.25, text: "$11,701 - $13,900" },
            { value: 5.85, text: "$13,901 - $21,400" },
            { value: 6.25, text: "$21,401 - $80,650" },
            { value: 6.85, text: "$80,651 - $215,400" },
            { value: 9.65, text: "$215,401 - $1,077,550" },
            { value: 10.30, text: "$1,077,551 - $5,000,000" },
            { value: 10.90, text: "Over $5,000,000" }
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 4, text: "$0 - $17,150" },
            { value: 4.5, text: "$17,151 - $23,600" },
            { value: 5.25, text: "$23,601 - $27,900" },
            { value: 5.85, text: "$27,901 - $43,000" },
            { value: 6.25, text: "$43,001 - $161,550" },
            { value: 6.85, text: "$161,551 - $323,200" },
            { value: 9.65, text: "$323,201 - $2,155,350" },
            { value: 10.30, text: "$2,155,351 - $5,000,000" },
            { value: 10.90, text: "Over $5,000,000" }
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 4, text: "$0 - $12,800" },
            { value: 4.5, text: "$12,801 - $17,650" },
            { value: 5.25, text: "$17,651 - $20,950" },
            { value: 5.85, text: "$20,951 - $32,200" },
            { value: 6.25, text: "$32,201 - $107,650" },
            { value: 6.85, text: "$107,651 - $269,300" },
            { value: 9.65, text: "$269,301 - $1,616,450" },
            { value: 10.30, text: "$1,616,451 - $5,000,000" },
            { value: 10.90, text: "Over $5,000,000" }
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 4, text: "$0 - $8,500" },
            { value: 4.5, text: "$8,501 - $11,700" },
            { value: 5.25, text: "$11,701 - $13,900" },
            { value: 5.85, text: "$13,901 - $21,400" },
            { value: 6.25, text: "$21,401 - $80,650" },
            { value: 6.85, text: "$80,651 - $215,400" },
            { value: 9.65, text: "$215,401 - $1,077,550" },
            { value: 10.30, text: "$1,077,551 - $5,000,000" },
            { value: 10.90, text: "Over $5,000,000" }
        ]
    },
    NC: [{ // North Carolina
        value: 4.5,
        text: "Flat rate."
    }],
    ND: { // North Dakota
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 1.1, text: "$0 - $41,775" },
            { value: 2.04, text: "$41,775 - $101,050" },
            { value: 2.27, text: "$101,050 - $210,852" },
            { value: 2.64, text: "Over $210,852" }
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 1.1, text: "$0 - $69,700" },
            { value: 2.04, text: "$69,700 - $168,450" },
            { value: 2.27, text: "$168,450 - $256,650" },
            { value: 2.64, text: "Over $256,650" }
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 1.1, text: "$0 - $41,775" },
            { value: 2.04, text: "$41,775 - $101,050" },
            { value: 2.27, text: "$101,050 - $210,852" },
            { value: 2.64, text: "Over $210,852" }
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 1.1, text: "$0 - $34,850" },
            { value: 2.04, text: "$34,850 - $84,225" },
            { value: 2.27, text: "$84,225 - $128,325" },
            { value: 2.64, text: "Over $128,325" }
        ]
    },
    OH: {  // Ohio
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 0, text: "$0 - $26,050" },
            { value: 2.75, text: "$26,051 - $100,000" },
            { value: 3.68, text: "$100,001 - $115,300" },
            { value: 3.75, text: "Over $115,300" }
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 0, text: "$0 - $26,050" },
            { value: 2.75, text: "$26,051 - $100,000" },
            { value: 3.68, text: "$100,001 - $115,300" },
            { value: 3.75, text: "Over $115,300" }
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 0, text: "$0 - $26,050" },
            { value: 2.75, text: "$26,051 - $100,000" },
            { value: 3.68, text: "$100,001 - $115,300" },
            { value: 3.75, text: "Over $115,300" }
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 0, text: "$0 - $26,050" },
            { value: 2.75, text: "$26,051 - $100,000" },
            { value: 3.68, text: "$100,001 - $115,300" },
            { value: 3.75, text: "Over $115,300" }
        ]
    },
    OK: { // Oklahoma
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 0.25, text: "$0 - $1,000" },
            { value: 0.75, text: "$1,000 - $2,500" },
            { value: 1.75, text: "$2,500 - $3,750" },
            { value: 2.75, text: "$3,750 - $4,900" },
            { value: 3.75, text: "$4,900 - $7,200" },
            { value: 4.75, text: "Over $7,200" },
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 0.25, text: "$0 - $1,000" },
            { value: 0.75, text: "$1,000 - $2,500" },
            { value: 1.75, text: "$2,500 - $3,750" },
            { value: 2.75, text: "$3,750 - $4,900" },
            { value: 3.75, text: "$4,900 - $7,200" },
            { value: 4.75, text: "Over $7,200" },
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 0.25, text: "$0 - $2,000" },
            { value: 0.75, text: "$2,000 - $5,000" },
            { value: 1.75, text: "$5,000 - $7,500" },
            { value: 2.75, text: "$7,500 - $9,800" },
            { value: 3.75, text: "$9,800 - $12,200" },
            { value: 4.75, text: "Over $12,200" },
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 0.25, text: "$0 - $2,000" },
            { value: 0.75, text: "$2,000 - $5,000" },
            { value: 1.75, text: "$5,000 - $7,500" },
            { value: 2.75, text: "$7,500 - $9,800" },
            { value: 3.75, text: "$9,800 - $12,200" },
            { value: 4.75, text: "Over $12,200" },
        ]
    },
    OR: { // Oregon
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 4.75, text: "$0 - $4,050" },
            { value: 6.75, text: "$4,050 - $10,200" },
            { value: 8.75, text: "$10,200 - $125,000" },
            { value: 9.9, text: "Over $125,000" }
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 4.75, text: "$0 - $8,100" },
            { value: 6.75, text: "$8,100 - $20,400" },
            { value: 8.75, text: "$20,400 - $250,000" },
            { value: 9.9, text: "Over $250,000" }
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 4.75, text: "$0 - $4,050" },
            { value: 6.75, text: "$4,050 - $10,200" },
            { value: 8.75, text: "$10,200 - $125,000" },
            { value: 9.9, text: "Over $125,000" }
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 4.75, text: "$0 - $4,050" },
            { value: 6.75, text: "$4,050 - $10,200" },
            { value: 8.75, text: "$10,200 - $125,000" },
            { value: 9.9, text: "Over $125,000" }
        ]
    },
    PA: [{ // Pennsylvania
        value: 3.07,
        text: "Flat rate."
    }],
    RI: [ // Rhode Island
        {
            value: 3.75,
            text: "$0 to $77,450"
        },
        {
            value: 4.75,
            text: "$77,450 to $176,050"
        },
        {
            value: 5.99,
            text: "Over $176,050"
        }
    ],
    SC: [ // South Carolina
        { value: 0, text: "$0 - $3,200" },
        { value: 3, text: "$3,201 - $16,040" },
        { value: 6.5, text: "$16,041 and above" }
    ],
    SD: [{  // South Dakota
        value: 0,
        text: "No state income tax."
    }],
    TN: [{  // Tennessee
        value: 0,
        text: "No state income tax."
    }],
    TX: [{  // Texas
        value: 0,
        text: "No state income tax."
    }],
    UT: [{ // Utah
        value: 4.65,
        text: "Flat rate."
    }],
    VT: { // Vermont
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 3.35, text: "$0 - $42,150" },
            { value: 6.60, text: "$42,151 - $102,200" },
            { value: 7.60, text: "$102,201 - $213,150" },
            { value: 8.75, text: "Over $213,150" }
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 3.35, text: "$0 - $70,450" },
            { value: 6.60, text: "$70,451 - $170,300" },
            { value: 7.60, text: "$170,301 - $259,500" },
            { value: 8.75, text: "Over $259,500" }
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 3.35, text: "$0 - $42,150" },
            { value: 6.60, text: "$42,151 - $102,200" },
            { value: 7.60, text: "$102,201 - $213,150" },
            { value: 8.75, text: "Over $213,150" }
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 3.35, text: "$0 - $35,225" },
            { value: 6.60, text: "$35,226 - $85,150" },
            { value: 7.60, text: "$85,151 - $129,750" },
            { value: 8.75, text: "Over $129,750" }
        ]
    },
    VA: [ // Virginia
        { value: 2, text: "$0 - $3,000" },
        { value: 3, text: "$3,001 - $5,000" },
        { value: 5, text: "$5,001 - $17,000" },
        { value: 5.75, text: "$17,001 and above" }
    ],
    WA: [{ // Washington
        value: 0,
        text: 'Does not have state income tax'
    }],
    WV: { // West Virginia
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 3, text: "$0 - $10,000" },
            { value: 4, text: "$10,001 - $25,000" },
            { value: 4.5, text: "$25,001 - $40,000" },
            { value: 6, text: "$40,001 - $60,000" },
            { value: 6.5, text: "Over $60,000" }
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 3, text: "$0 - $10,000" },
            { value: 4, text: "$10,001 - $25,000" },
            { value: 4.5, text: "$25,001 - $40,000" },
            { value: 6, text: "$40,001 - $60,000" },
            { value: 6.5, text: "Over $60,000" }
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 3, text: "$0 - $10,000" },
            { value: 4, text: "$10,001 - $25,000" },
            { value: 4.5, text: "$25,001 - $40,000" },
            { value: 6, text: "$40,001 - $60,000" },
            { value: 6.5, text: "Over $60,000" }
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 3, text: "$0 - $10,000" },
            { value: 4, text: "$10,001 - $25,000" },
            { value: 4.5, text: "$25,001 - $40,000" },
            { value: 6, text: "$40,001 - $60,000" },
            { value: 6.5, text: "Over $60,000" }
        ]
    },
    WI: { // Wisconsin
        [FILING_STATUS_ENUM.SINGLE]: [
            { value: 3.5, text: "$0 - $13,810" },
            { value: 4.4, text: "$13,810 - $27,630" },
            { value: 5.3, text: "$27,630 - $304,170" },
            { value: 7.65, text: "Over $304,170" }
        ],
        [FILING_STATUS_ENUM.FILLING_JOINTLY]: [
            { value: 3.5, text: "$0 - $18,420" },
            { value: 4.4, text: "$18,420 - $36,840" },
            { value: 5.3, text: "$36,840 - $405,550" },
            { value: 7.65, text: "Over $405,550" }
        ],
        [FILING_STATUS_ENUM.HEAD]: [
            { value: 3.5, text: "$0 - $13,810" },
            { value: 4.4, text: "$13,810 - $27,630" },
            { value: 5.3, text: "$27,630 - $304,170" },
            { value: 7.65, text: "Over $304,170" }
        ],
        [FILING_STATUS_ENUM.FILLING_SEPARATELY]: [
            { value: 3.5, text: "$0 - $9,210" },
            { value: 4.4, text: "$$9,210 - $18,420" },
            { value: 5.3, text: "$18,420 - $202,780" },
            { value: 7.65, text: "Over $202,780" }
        ]
    },
    WY: [{ // Wyoming
        value: 0,
        text: 'Does not have state income tax'
    }]
}

export const STATE_OPTIONS: StateOption[] = [
    { text: "Alabama", value: "AL" },
    { text: "Alaska", value: "AK" },
    { text: "Arizona", value: "AZ" },
    { text: "Arkansas", value: "AR" },
    { text: "California", value: "CA" },
    { text: "Colorado", value: "CO" },
    { text: "Connecticut", value: "CT" },
    { text: "Delaware", value: "DE" },
    { text: "District of Columbia", value: "DC" },
    { text: "Florida", value: "FL" },
    { text: "Georgia", value: "GA" },
    { text: "Hawaii", value: "HI" },
    { text: "Idaho", value: "ID" },
    { text: "Illinois", value: "IL" },
    { text: "Indiana", value: "IN" },
    { text: "Iowa", value: "IA" },
    { text: "Kansas", value: "KS" },
    { text: "Kentucky", value: "KY" },
    { text: "Louisiana", value: "LA" },
    { text: "Maine", value: "ME" },
    { text: "Maryland", value: "MD" },
    { text: "Massachusetts", value: "MA" },
    { text: "Michigan", value: "MI" },
    { text: "Minnesota", value: "MN" },
    { text: "Mississippi", value: "MS" },
    { text: "Missouri", value: "MO" },
    { text: "Montana", value: "MT" },
    { text: "Nebraska", value: "NE" },
    { text: "Nevada", value: "NV" },
    { text: "New Hampshire", value: "NH" },
    { text: "New Jersey", value: "NJ" },
    { text: "New Mexico", value: "NM" },
    { text: "New York", value: "NY" },
    { text: "North Carolina", value: "NC" },
    { text: "North Dakota", value: "ND" },
    { text: "Ohio", value: "OH" },
    { text: "Oklahoma", value: "OK" },
    { text: "Oregon", value: "OR" },
    { text: "Pennsylvania", value: "PA" },
    { text: "Rhode Island", value: "RI" },
    { text: "South Carolina", value: "SC" },
    { text: "South Dakota", value: "SD" },
    { text: "Tennessee", value: "TN" },
    { text: "Texas", value: "TX" },
    { text: "Utah", value: "UT" },
    { text: "Vermont", value: "VT" },
    { text: "Virginia", value: "VA" },
    { text: "Washington", value: "WA" },
    { text: "West Virginia", value: "WV" },
    { text: "Wisconsin", value: "WI" },
    { text: "Wyoming", value: "WY" },
];