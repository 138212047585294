import Stripe from 'stripe';

import { FetchResponse, FetchSuccess, JsonResult, PagingDataResponse } from '../types';
import {
  DefaultFetchError,
  FetchCreate,
  FetchDelete,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchCreate,
  useFetchDelete,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';

interface IPaymentMethod extends Stripe.PaymentMethod {
  isDefault: boolean;
}

interface IPaymentMethods {
  data: IPaymentMethod[];
}

interface IPaymentMethodSetAsDefaultParams {
  pmId: string;
}

interface IPaymentMethodCreateParams {
  pmId: string;
  planId: string;
  promoCode?: string;
  isFreeTrial?: boolean;
}

interface IPaymentMethodUpgradeParams {
  planId: string;
  type: string | undefined;
}

export interface IInvoicesGetParams {
  page?: number;
  limit?: number;
}

export interface IInvoice extends Stripe.Invoice {
  key: string;
}

interface IInvoicesTableData {
  invoices: IInvoice[];
  total: number;
}

interface IPlan {
  data: Stripe.Plan;
}

interface IPlans {
  data: Stripe.Plan[];
}

interface ISubscription extends Stripe.Subscription {
  plan: Stripe.Plan;
}

interface ICheckCoupon {
  promoCode: string;
}

interface IPlansParams {
  type: 'month' | 'year';
}

export const useTableInvoiceRow = (): FetchGetId<IInvoicesTableData, DefaultFetchError, IInvoicesGetParams> =>
  useCustomerInvoices(
    (response: FetchResponse<PagingDataResponse<Stripe.Invoice>>): IInvoicesTableData => ({
      invoices: response.data.data.map(
        (item): IInvoice => ({
          ...item,
          key: item.id,
        })
      ),
      total: response.data.data.length,
    })
  );

export const useCheckCoupon = (): FetchCreate<ICheckCoupon, DefaultFetchError, ICheckCoupon> =>
  useFetchCreate('payment/check-coupon', { autoStart: false });

export const usePlans = (): FetchGet<IPlans, IPlansParams> => useFetchGet('plans', { autoStart: false });
export const usePlanId = (): FetchGetId<IPlan> =>
  useFetchGetId('plans/plan', '', { autoStart: false, startStateLoading: false, multiple: 'planId' });

export const usePaymentMethodId = (): FetchGetId<FetchResponse<IPaymentMethod>> =>
  useFetchGetId('payment', '', { autoStart: false });
export const useCustomerPaymentMethods = (): FetchGetId<FetchResponse<IPaymentMethods>> =>
  useFetchGetId('payment/customer', '', { autoStart: false, startStateLoading: true, multiple: 'paymentMethods' });
export const usePaymentMethodAttach = (): FetchCreate<FetchResponse<string>> =>
  useFetchCreate('payment/attach/:id', { autoStart: false });
export const usePaymentMethodCreate = (): FetchCreate<
  FetchResponse<string>,
  DefaultFetchError,
  IPaymentMethodSetAsDefaultParams
> => useFetchCreate('payment/method', { autoStart: false });
export const usePaymentMethodDelete = (): FetchDelete<FetchResponse<string>> => useFetchDelete('payment');
export const usePaymentMethodSetAsDefault = (): FetchUpdate<
  FetchSuccess,
  DefaultFetchError,
  IPaymentMethodSetAsDefaultParams
> => useFetchUpdate('payment');
export const usePaymentCreate = (): FetchCreate<
  FetchResponse<JsonResult>,
  DefaultFetchError,
  IPaymentMethodCreateParams
> => useFetchCreate('payment', { autoStart: false });

export const usePaymentUpgrade = (): FetchCreate<
  FetchResponse<JsonResult>,
  DefaultFetchError,
  IPaymentMethodUpgradeParams
> => useFetchCreate('payment/upgrade', { autoStart: false });

export function useCustomerInvoices<D = FetchResponse<PagingDataResponse<Stripe.Invoice[]>>, DD = D>(
  decorateData?: (data: D) => DD
): FetchGetId<DD, DefaultFetchError, IInvoicesGetParams> {
  return useFetchGetId<D, DefaultFetchError, unknown, DD>('payment/invoices', '', {
    decorateData,
    autoStart: false,
  });
}

export const useSubscriptionId = (): FetchGetId<FetchResponse<ISubscription>> =>
  useFetchGetId('payment/subscription', '', { autoStart: false, startStateLoading: false, multiple: 'subscriptionId' });

export const useCancelSubscription = (): FetchDelete<FetchSuccess> => useFetchDelete('payment/cancel/subscription', '');
