import * as Sentry from '@sentry/react';
import { JsonResult } from '../types';

export const getDataInStorage = (name: string): JsonResult => {
  try {
    return JSON.parse(localStorage.getItem(name) || '{}');
  } catch (err) {
    Sentry.captureException(err);
    // eslint-disable-next-line no-console
    console.warn(`localStorage: ${name}`, err);
    throw err;
  }

  return {};
};

export const getDataInSessionStorage = (name: string): JsonResult => {
  try {
    return JSON.parse(sessionStorage.getItem(name) || '{}');
  } catch (err) {
    Sentry.captureException(err);
    // eslint-disable-next-line no-console
    console.warn(`sessionStorage: ${name}`, err);
    throw err;
  }

  return {};
};

export const setDataInStorage = (name: string, data: JsonResult, marge = false): void => {
  let newData = data;

  if (marge) {
    const oldData = getDataInStorage(name);

    newData = { ...oldData, ...data };
  }

  let str = '';

  try {
    str = JSON.stringify(newData);
  } catch (e) {
    Sentry.captureException(e);
    // eslint-disable-next-line no-console
    console.warn(`localStorage: ${name}`, e);
    throw e;
  }

  localStorage.setItem(name, str);
};

export const setDataInSessionStorage = (name: string, data: JsonResult, marge = false): void => {
  let newData = data;

  if (marge) {
    const oldData = getDataInSessionStorage(name);

    newData = { ...oldData, ...data };
  }

  let str = '';

  try {
    str = JSON.stringify(newData);
  } catch (e) {
    Sentry.captureException(e);
    // eslint-disable-next-line no-console
    console.warn(`sessionStorage: ${name}`, e);
    throw e;
  }

  sessionStorage.setItem(name, str);
};
