import { Tag } from 'antd';
import React from 'react';
import { capitalize } from '../../../../../utils/text';

const colors = {
  report: {
    text: 'var(--color-white)',
    background: 'var(--color-tag-purple)',
  },
  system: {
    text: 'var(--color-white)',
    background: 'var(--color-tag-yellow)',
  },
  activity: {
    text: 'var(--color-white)',
    background: 'var(--color-tag-blue)',
  },
};

type Type = 'report' | 'system' | 'activity';

interface IType {
  type: string;
}

const Type = ({ type }: IType) => (
  <Tag color={colors[type as Type]?.background} style={{ color: colors[type as Type]?.text, padding: '4px 8px' }}>
    {capitalize(type)}
  </Tag>
);

export default Type;
