import { Typography } from 'antd';
import clsx from 'clsx';
import { Logo } from '../../Common/Icon';
import Simple from '../Simple';

import styles from './index.module.less';

interface Simple {
  children: React.ReactNode;
}

const Auth = ({ children }: Simple): JSX.Element => (
  <Simple>
    <div className={styles.wrapper}>
      <div className={clsx([styles.container, 'page-container'])}>
        <div className={styles.content}>
          <Logo className={styles.logo} />
          {children}
        </div>
        <div className={styles.info}>
          <Typography.Title className={styles.title}>
            Try Mortgage Maker and start to generate custom presentations for your clients
          </Typography.Title>
          <div className={styles.image}>
            <img src="/phone.png" alt="phone" className={styles.phone} />
            <img src="/report-builder.png" alt="report builder" className={styles.laptop} />
          </div>
        </div>
      </div>
    </div>
  </Simple>
);

export default Auth;
