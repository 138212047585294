import { Button, Input, Modal, ModalFuncProps, Radio, RadioChangeEvent, Typography } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';

interface Reason {
  [key: string]: {
    title: string;
    text?: string;
  };
}

const reasons: Reason = {
  lackOfCertainFeatures: {
    title: 'Lack of Certain Features:',
    text: 'You felt that some essential features were missing in our software.',
  },
  closingYourBusiness: {
    title: 'Closing Your Business:',
    text: 'Your business operations are winding up, and hence, you no longer require our services.',
  },
  costReduction: {
    title: 'Cost Reduction:',
    text: "You're streamlining your expenses and reducing costs on software solutions.",
  },
  switchingSoftwareProviders: {
    title: 'Switching Software Providers:',
    text: "You've decided to move to a different software provider.",
  },
  other: {
    title: 'Other',
    text: 'Any other reasons not specified above',
  },
};

const CancelModal = ({ open, onOk, onCancel }: ModalFuncProps) => {
  const [reasonType, setReasonType] = useState<string>();
  const [reason, setReason] = useState<string>();

  const handleReasonTypeChange = (e: RadioChangeEvent) => {
    const type = e.target.value;

    if (type !== 'other' && reasons[type]) {
      setReason(reasons[type].text);
    } else {
      setReason(undefined);
    }

    setReasonType(type);
  };

  useEffect(() => {
    if (!open) return;

    setReason(undefined);
    setReasonType(undefined);
  }, [open]);

  const handleOk = () => {
    onOk?.(reason);
  };

  const handleReasonChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;

    setReason(target.value);
  };

  return (
    <Modal closable={false} footer={null} open={open} onCancel={onCancel}>
      <div className="flex-col gap-16">
        <Typography.Title level={2}>Farewell, Yet Not Goodbye</Typography.Title>
        <Typography>
          We're deeply saddened to see you considering departure. Your journey with us has been invaluable, and your
          feedback is crucial for our growth and improvement.
        </Typography>
        <Typography>
          Could you kindly take a moment to share the reasons behind your decision? This will allow us to identify areas
          of improvement and strive towards better service.
        </Typography>
        <Radio.Group onChange={handleReasonTypeChange} value={reasonType}>
          {Object.entries(reasons).map(([key, value]) => (
            <Radio key={key} value={key}>
              <div className="flex-col gap-4 p-block-10">
                <Typography.Text className="fw-500">{value.title}</Typography.Text>
                {value?.text && <Typography.Text className="color-gray">{value.text}</Typography.Text>}
              </div>
            </Radio>
          ))}
        </Radio.Group>
        {reasonType === 'other' && (
          <Input.TextArea
            value={reason}
            onChange={handleReasonChange}
            placeholder="Enter reason"
            autoSize={{ minRows: 5, maxRows: 5 }}
          />
        )}
        <div className="flex-row flex-justify-end gap-12">
          <Button type="default" size="large" onClick={onCancel}>
            Back
          </Button>
          <Button type="primary" size="large" onClick={handleOk} disabled={!reason}>
            Cancel subscription
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelModal;
