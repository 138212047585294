import React, { useState } from 'react';
import Icon from '@ant-design/icons';
import { Typography, Button, Input } from 'antd';
import moment from 'moment';
import { useContextNotifications } from '../../../../../context/notifications';
import { copyTextToClipboard } from '../../../../../utils/text';
import { Dot, Link } from '../../../../Common/Icon';

import './infoCardStyles.less';
import { useUpdateNews } from '../../../../../hooks/news';

type CardProps = {
  id?: number;
  imgUrl?: string;
  category?: string;
  header?: string;
  pubDate?: string;
  author?: string;
  origin?: string;
  link?: string;
  toggleUpdate: () => void;
};

export const Card: React.FC<CardProps> = ({
  id,
  imgUrl,
  category,
  header,
  pubDate,
  author,
  origin,
  link,
  toggleUpdate,
}) => {
  const [editDetails, setEditDetails] = useState({ category, header });
  const { openNotification } = useContextNotifications();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const update = useUpdateNews();

  const handleEdit = () => setIsEdit(true);

  const handleSave = () => {
    update
      .fetch(
        {
          category: editDetails.category,
          header: editDetails.header,
        },
        id
      )
      .then(() => {
        toggleUpdate?.();
        setIsEdit(false);
      });
  };

  const handleCancel = () => {
    setIsEdit(false);
    setEditDetails({ category, header });
  };

  const handleChange = (key: string, value: string) => {
    setEditDetails((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <div className="card-container">
      <div className="card-image">
        <img
          src={
            imgUrl || 'https://www.shutterstock.com/image-vector/no-image-vector-symbol-missing-260nw-1310632172.jpg'
          }
          alt="News"
        />
      </div>
      <div className="card-details">
        {isEdit ? (
          <Input value={editDetails.category} onChange={(e) => handleChange('category', e.target.value)} />
        ) : (
          <Typography.Text className="category-text">{editDetails.category}</Typography.Text>
        )}
        <div>
          {isEdit ? (
            <Input.TextArea
              rows={2}
              size="large"
              className="header-input"
              value={editDetails.header}
              onChange={(e) => handleChange('header', e.target.value)}
            />
          ) : (
            <Typography.Text className="header-text">{editDetails.header}</Typography.Text>
          )}

          <div className="details-text">
            <Typography.Text>{moment(pubDate).format('MMM DD, YYYY').toUpperCase()}</Typography.Text>
            <Dot />
            <Typography.Text>by {author || origin}</Typography.Text>
          </div>
        </div>
        {isEdit ? (
          <div className="actions-container">
            <Button
              size="large"
              type="primary"
              disabled={!editDetails.category || !editDetails.header}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button size="large" type="primary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        ) : (
          <div className="actions-container">
            <Button
              className="button"
              size="large"
              onClick={() =>
                copyTextToClipboard(link || '').then(() => {
                  openNotification?.({
                    message: 'The link is copied to the clipboard',
                  });
                })
              }
            >
              <Icon component={Link} />
            </Button>
            <Button size="large" type="primary" onClick={handleEdit}>
              Edit
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

Card.defaultProps = {
  id: undefined,
  imgUrl: undefined,
  category: undefined,
  header: undefined,
  pubDate: undefined,
  author: undefined,
  origin: undefined,
  link: undefined,
};
