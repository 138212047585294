import { Space, Typography } from 'antd';
import { NamePath } from 'rc-field-form/lib/interface';
import React from 'react';
import { useContextReports } from '../../../context/reports';
import CustomInput from '../CustomInput';

interface ITextWithLabel {
  label?: string;
  value: string;
  interval?: string;
  name?: NamePath;
  identifier?: NamePath;
}
const TextWithLabel = ({ label, value, interval, name, identifier }: ITextWithLabel) => {
  const { isBorrower, handleLoansUpdate } = useContextReports();

  return (
    <Space direction="vertical" size={4}>
      <Typography.Text className="color-gray fs-0875 ws-nowrap">{label}</Typography.Text>
      <Space size={4} className="flex-align-end">
        {name && !isBorrower && identifier ? (
          <CustomInput
            rowStyles={{ width: 'fit-content' }}
            identifier={identifier}
            formItemProps={{ name, className: 'table-form-item' }}
            inputClassName="title-input color-dark-blue fs-2 fw-600 p-0"
            placeholder="$0"
            prefix="$"
            onBlur={handleLoansUpdate}
          />
        ) : (
          <Typography.Title level={2} className="color-dark-blue fs-2 ws-nowrap">
            {value}
          </Typography.Title>
        )}

        {interval && <Typography.Text className="color-gray fs-0875 ws-nowrap">/ {interval}</Typography.Text>}
      </Space>
    </Space>
  );
};

TextWithLabel.defaultProps = {
  label: undefined,
  interval: undefined,
  name: undefined,
  identifier: undefined,
};

export default TextWithLabel;
