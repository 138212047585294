import { Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { IEvent } from '../../Pages/Reports/types';
import styles from './index.module.less';

interface ILinearCalendar {
  maxDays: number;
  events: IEvent[];
  eventType?: 'simple' | 'advanced';
  step?: number;
}

const LinearCalendar = ({ maxDays, events, eventType, step }: ILinearCalendar) => {
  const days = Array.from({ length: maxDays }, (_, i) => i + 1).filter((day: number) =>
    step ? day % step !== 0 : true
  );
  const containerRef = useRef<HTMLDivElement | null>(null);
  const eventsRef = useRef<HTMLDivElement | null>(null);
  const [dayWidth, setDayWidth] = useState<number>(0);

  useEffect(() => {
    if (!containerRef || !containerRef.current?.offsetWidth || !days?.length) return;

    if (eventType === 'simple') {
      setDayWidth(25);

      return;
    }

    setDayWidth(containerRef.current.offsetWidth / (days.length - 1) / (step || 1));
  }, [containerRef, days]);

  const getEventPosition = (event: IEvent, eventIndex: number) => {
    const eventHeight = eventType === 'simple' ? 30 : 60;
    const day = Number(event.day);

    const position = { left: 0, top: 0 };

    position.left = (day - 1) * dayWidth;

    if (eventType === 'simple') {
      for (let i = eventIndex - 1; i >= 0; i--) {
        if (events[i] && Number(events[i].day) + Number(events[i].duration) >= Number(events[i + 1].day)) {
          position.top = (eventIndex - i) * eventHeight;
          if (eventsRef?.current?.style) {
            eventsRef.current.style.height = `${(eventIndex - i) * eventHeight + 40}px`;
          }
        } else {
          break;
        }
      }
    } else {
      for (let i = eventIndex; i >= 0; i--) {
        position.top = (eventIndex - i) * eventHeight + 20;
      }
      if (eventsRef?.current?.style) {
        eventsRef.current.style.height = `${events.length * eventHeight + 60}px`;
      }
    }

    return position;
  };

  return (
    <div
      style={eventType === 'simple' ? { maxWidth: '1160px', overflowX: 'auto', overflowY: 'hidden' } : {}}
      className={clsx(styles.row, { 'flex-justify-center': eventType === 'simple' })}
      ref={containerRef}
    >
      <div className={styles.days}>
        {days.map((day) => (
          <div key={day} style={{ minWidth: '25px' }} className={styles.day}>
            <div className={styles.dayLine} />
            <Typography.Text className={styles.dayNumber}>{day}</Typography.Text>
          </div>
        ))}
      </div>

      <div className={styles.events} ref={eventsRef}>
        {events
          ?.sort((a, b) => Number(a.day) - Number(b.day))
          ?.filter((item) => item.day)
          ?.map((event, index) =>
            eventType === 'simple' ? (
              <div
                key={event.id}
                className={styles.simpleEvent}
                style={{ ...getEventPosition(event, index), width: dayWidth * Number(event.duration) || 1 }}
              >
                <div className={styles.eventLabel}>
                  <Tooltip showArrow={false} title={event.label}>
                    <Typography.Text className={event.id === 3 ? styles.closeOfEscrow : styles.label}>
                      {event.label || (event.id === 3 && 'Close of escrow')}
                    </Typography.Text>
                  </Tooltip>
                </div>
                <div className={styles.bar} style={{ background: event.color }} />
              </div>
            ) : (
              <div
                key={event.id}
                className={styles.advancedEvent}
                style={{ ...getEventPosition(event, index), minWidth: dayWidth * Number(event.duration) || 1 }}
              >
                <div
                  className={clsx('flex-row', 'gap-4', styles.content)}
                  style={{ background: 'white', boxShadow: '0px 4px 12px 0px #0000000F', padding: 4 }}
                >
                  <div className={styles.bar} style={{ background: event.color }} />
                  <div className="flex-col gap-4">
                    <Typography.Title level={4}>{event.label}</Typography.Title>
                    <Typography.Text className="fs-0875 color-gray">{event.range}</Typography.Text>
                  </div>
                </div>
              </div>
            )
          )}
      </div>
    </div>
  );
};

LinearCalendar.defaultProps = {
  eventType: 'simple',
  step: undefined,
};

export default LinearCalendar;
