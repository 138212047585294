import React, { PropsWithChildren } from 'react';
import { Layout } from 'antd';
import Header from './Header';

import styles from '../index.module.less';
import SiderCommon from './Sider';

interface IMain extends PropsWithChildren {
  withSearch?: boolean;
}

const Main: React.FC<IMain> = ({ withSearch, children }): JSX.Element => (
  <Layout className={styles.layout}>
    <Layout.Sider className={styles.siderWrapper}>
      <SiderCommon />
    </Layout.Sider>
    <div className={styles.container}>
      <div className="d-m-none">
        <Header withSearch={withSearch} />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  </Layout>
);

Main.defaultProps = {
  withSearch: false,
};

export default Main;
