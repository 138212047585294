/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, List, Modal, Select, Typography } from 'antd';
import { INews, useDeleteNews, useNewsRegenerate, useNewsWeekly, useUpdateNews } from '../../../hooks/news';
import { useContextNotifications } from '../../../context/notifications';

const pageSize = 5;

const WeeklyNewsTable = () => {
  const [news, setNews] = useState<INews[]>([]);
  const { openNotification } = useContextNotifications();
  const [currentNews, setCurrentNews] = useState<INews | null>(null);
  const [form] = Form.useForm();
  const [prompts, setPrompts] = useState<string[] | null>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRegenerateVisible, setIsRegenerateVisible] = useState(false);
  const getWeeklyNews = useNewsWeekly();
  const newsDelete = useDeleteNews();
  const updateNews = useUpdateNews();
  const regenerateArticle = useNewsRegenerate();

  const handleNewsDelete = (id: number) => {
    if (!id) return;
    newsDelete.fetch(id.toString()).then(() => {
      setNews(news.filter((newsItem) => newsItem.id !== id));
      openNotification?.({ message: 'Deleted successfully' });
    });
  };

  const showEditModal = (newsItem: INews) => {
    setCurrentNews(newsItem);
    form.setFieldsValue({
      header: newsItem.header,
      body: newsItem.body,
    });
    setIsModalVisible(true);
  };

  const showRegenerateModal = (newsItem: INews) => {
    setCurrentNews(newsItem);
    const parsedPrompts = JSON.parse(newsItem.prompts);
    const contentOnly = parsedPrompts.map((prompt: { content: string; role: string }) => prompt.content);

    setPrompts(contentOnly);
    setIsRegenerateVisible(true);
  };

  const fetchWeeklyNews = () => {
    getWeeklyNews
      .fetch()
      .then((res) => {
        if (res?.data?.data) {
          const sortedNews = [...res.data.data].sort((a, b) => {
            const dateA = new Date(a.pubDate).getTime();
            const dateB = new Date(b.pubDate).getTime();

            return dateB - dateA;
          });

          setNews(sortedNews);
        }
      })
      .catch((error) => {
        openNotification?.({
          message: `Error while Getting News: ${error}`,
          type: 'error',
        });
      });
  };

  useEffect(() => {
    fetchWeeklyNews();
  }, []);

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentNews(null);
  };

  const handleCancelRegenerate = () => {
    setIsRegenerateVisible(false);
    setPrompts([]);
    setCurrentNews(null);
  };

  const handleChangePrompts = (selectedItems: string[]) => {
    setPrompts(selectedItems);
  };

  const handleEdit = () => {
    form
      .validateFields()
      .then((values) => {
        updateNews
          .fetch(values, currentNews?.id)
          .then(() => {
            openNotification?.({
              message: 'Updated successfully!',
              type: 'success',
            });
          })
          .finally(() => {
            setIsModalVisible(false);
            fetchWeeklyNews();
          });
      })
      .catch((error) => {
        openNotification?.({
          message: `Error while Getting News: ${error}`,
          type: 'error',
        });
      });
  };

  const handleRegenerate = () => {
    regenerateArticle
      .fetch({ prompts }, currentNews?.id)
      .then(() => {
        setIsRegenerateVisible(false);
        openNotification?.({
          message: 'Regenerated successfully!',
          type: 'success',
        });
      })
      .catch((error) => {
        openNotification?.({
          message: `Error while Getting News: ${error}`,
          type: 'error',
        });
      })
      .finally(() => {
        setPrompts([]);
        setCurrentNews(null);
        fetchWeeklyNews();
      });
  };

  return (
    <>
      <List
        loading={getWeeklyNews.loading || newsDelete.loading || updateNews.loading || regenerateArticle.loading}
        itemLayout="vertical"
        size="large"
        pagination={{
          pageSize,
          position: 'top',
        }}
        dataSource={news}
        renderItem={(item) => (
          <List.Item
            key={item.id}
            actions={[
              <Button type="primary" key="edit" onClick={() => showEditModal(item)}>
                Edit
              </Button>,
              <Button type="primary" key="regenerate" onClick={() => showRegenerateModal(item)}>
                Regenerate
              </Button>,
              <Button danger type="primary" key="delete" onClick={() => handleNewsDelete(item.id)}>
                Delete
              </Button>,
            ]}
          >
            <List.Item.Meta title={<h2>{item.header}</h2>} />
            <div dangerouslySetInnerHTML={{ __html: item.body }} />
          </List.Item>
        )}
      />
      <Modal
        confirmLoading={updateNews.loading}
        centered
        open={isModalVisible}
        onOk={handleEdit}
        onCancel={handleCancel}
        okText="Save"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            name="header"
            label={<h2>Title</h2>}
            rules={[{ required: true, message: 'Please input the header!' }]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item name="body" label={<h2>Text</h2>} rules={[{ required: true, message: 'Please input the body!' }]}>
            <Input.TextArea rows={14} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        confirmLoading={regenerateArticle.loading}
        width="70%"
        centered
        open={isRegenerateVisible}
        onOk={handleRegenerate}
        onCancel={handleCancelRegenerate}
        okText="Regenerate"
        cancelText="Cancel"
      >
        <Typography.Title level={3}>Select or Input prompts</Typography.Title>
        <br />
        <Select
          mode="tags"
          placeholder="Please select or add prompts"
          onChange={handleChangePrompts}
          style={{ width: '100%' }}
          defaultValue={prompts}
        />
      </Modal>
    </>
  );
};

export default WeeklyNewsTable;
