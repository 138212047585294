import { Button, Typography } from 'antd';
import React, { useState } from 'react';
import { useContextUsers } from '../../../../../context/users';
import { useCancelSubscription } from '../../../../../hooks/stripe';
import ContentCard from '../../../../Common/ContentCard';
import CancelModal from './CancelModal';

const CancelSubscription = () => {
  const { profile, getProfile } = useContextUsers();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { fetch, loading } = useCancelSubscription();

  const handleCancelSubscription = () => {
    if (!profile?.subscriptionId || !getProfile) return;

    setIsModalOpen(false);
    fetch(profile?.subscriptionId).then(() => getProfile());
  };

  const handleOpenCancelModal = () => {
    setIsModalOpen(true);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <ContentCard
      bordered
      padding={20}
      footer={
        <Button
          className="btn-default-dark-blue"
          type="default"
          size="large"
          onClick={handleOpenCancelModal}
          loading={loading}
        >
          Cancel
        </Button>
      }
    >
      <CancelModal open={isModalOpen} onCancel={handleModalCancel} onOk={handleCancelSubscription} />
      <div className="flex-col gap-16">
        <Typography.Text className="fs-1 fw-600">Considering ending your subscription?</Typography.Text>
        <Typography.Text className="color-gray">
          Please be aware that if you choose to terminate your subscription, it will stay active until the conclusion of
          your current billing cycle. We reserve the right delete your account and remove your data from our servers
          after 90 days from your cancellation date. This encompasses all information tied to your account, which
          includes but is not limited to, report links, usage history, referrals, and analytics.
        </Typography.Text>
      </div>
    </ContentCard>
  );
};

export default CancelSubscription;
