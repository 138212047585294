import { JsonResult } from '../../../../types';
import styles from '../index.module.less';
import Tile from '../Tile';

interface IList {
  scripts: JsonResult[];
}

const List = ({ scripts }: IList) => (
  <div className={styles.scriptsGrid}>
    {scripts.map((script) => (
      <div key={script.id} className={styles.scriptCol}>
        <Tile script={script} />
      </div>
    ))}
  </div>
);

export default List;
