import { useState } from 'react';
import { Button, Form, Input, Modal, Typography } from 'antd';
import { useUserViewCreate } from '../../../../hooks/userViews';

interface BorrowerModalProps {
  reportId: string;
  viewIp: string;
  alreadyViewed: boolean;
}

const BorrowerModal = ({ reportId, viewIp, alreadyViewed }: BorrowerModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const createView = useUserViewCreate?.()

  const handleFinish = (values: { name: string; email?: string }) => {
    if (!reportId) return;

    createView.fetch({ name: values.name, email: values.email || undefined, viewIp, reportId })
      .finally(() => {
        setIsOpen(false);
      });
  };

  return !alreadyViewed ? (
    <Modal closable={false} footer={null} open={isOpen}>
      <div className='flex-col gap-16'>
        <Typography className='text-l'>
          Legal Disclaimer
          <br />
          <br />
          The mortgage loan options provided here are preliminary and for general reference only. The actual rate,
          payment, and costs could be higher. Get an official Loan Estimate before choosing a loan. § 1026.19(e)(2)(ii)
        </Typography>
        <Typography className='text-l'>
          Let us know who you are
        </Typography>
        <Form
          layout='vertical'
          onFinish={handleFinish}>
          <Form.Item
            name='name'
            rules={[{ required: true, message: 'Please enter your name to continue' }]}
          >
            <Input
              size='large'
              placeholder='Name' />
          </Form.Item>
          <Form.Item
            name='email'
            rules={[{ type: 'email', message: 'Please enter a valid email' }]}
          >
            <Input
              size='large'
              placeholder='Email (optional)' />
          </Form.Item>
          <div className='flex-row flex-justify-end'>
            <Button
              type='default'
              size='large'
              htmlType='submit'>
              Continue
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  ) : (
    <div />
  );
};

export default BorrowerModal;
