import { InboxOutlined } from '@ant-design/icons';
import { Typography, UploadFile, UploadProps } from 'antd';
import { RcFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useReportFileUpload } from '../../../../hooks/reports';
import Loading from '../../../Common/Loading';

const UploadQuote = () => {
  const fileUpload = useReportFileUpload();
  const { id } = useParams();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleUpload = () => {
    if (!id) return;

    const formData = new FormData();

    formData.append('reportId', id);
    formData.append('file', fileList[0].originFileObj as RcFile);

    fileUpload.fetch(formData).then(() => {
      setIsFileUploaded(true);
    });
  };

  return (
    <div className="content-lg flex-col flex-justify-center w-100 h-100">
      <Loading visible={fileUpload.loading} absolute />
      {!isFileUploaded && (
        <div className="flex-col flex-align-center gap-20 w-100">
          <Typography.Title>Upload Quote</Typography.Title>
          <div style={{ width: '100%', maxWidth: 500 }}>
            <Dragger
              onChange={onChange}
              accept=".pdf"
              maxCount={1}
              multiple={false}
              customRequest={handleUpload}
              showUploadList={false}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">Supports PDF only</p>
            </Dragger>
          </div>
        </div>
      )}
      {isFileUploaded && (
        <div className="flex-col flex-align-center gap-20">
          <Typography.Title>Thank you!</Typography.Title>
          <Typography.Text>Quote was uploaded successfully.</Typography.Text>
        </div>
      )}
    </div>
  );
};

export default UploadQuote;
