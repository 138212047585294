import Layout from '../../components/Layout/Main';
import Content from '../../components/Pages/Reports';
import { displayName } from '../../config';

const Reports = (): JSX.Element => {
  document.title = `${displayName}: Reports`;

  return (
    <Layout withSearch>
      <Content />
    </Layout>
  );
};

export default Reports;
