import { useEffect, useState } from 'react';
import { Col, Form, Row, Typography } from 'antd';
import { DraggableProvided } from 'react-beautiful-dnd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import debounce from "just-debounce-it";
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import ReportSection from '../Section';
import { useContextUsers } from '../../../../../context/users';
import { useContextReports } from '../../../../../context/reports';
import { editorModules, editorModuleReadOnly } from '../WelcomeText';
import './style.less';

interface IClosingText {
  provided?: DraggableProvided;
}

const ClosingText = ({ provided }: IClosingText) => {
  const name = 'closingText';
  const form = useFormInstance();
  const { profile } = useContextUsers();
  const { isBorrower, report, handleReportUpdate } = useContextReports();
  const [htmlContent, setHtmlContent] = useState<string | null | undefined>(report?.closingText);

  useEffect(() => {
    if (isBorrower && !report?.closingText) return;
    setHtmlContent(report?.closingText);
  }, [profile, report]);

  const debouncedHandleReportUpdate = debounce(() => {
    handleReportUpdate?.();
  }, 500);

  const handleEditorChange = (content: string) => {
    setHtmlContent(content);
    form.setFieldValue(['report', 'closingText'], content);
    debouncedHandleReportUpdate();
  };

  if (isBorrower && !htmlContent) return null;

  return (
    <ReportSection name={name} title={!isBorrower ? 'Closing Message' : ''} provided={provided}>
      <Row>
        <Col xl={24} span={24}>
          <Form.Item name={['report', name]} label={!isBorrower ? 'Enter Text' : undefined}>
            {isBorrower ? (
              <div className="custom-quill-editor">
                <ReactQuill theme="snow" value={htmlContent || ''} modules={editorModuleReadOnly} readOnly />
              </div>
            ) : (
              <Form.Item name={['report', 'closingText']} label={!isBorrower ? 'Enter Text' : undefined} noStyle>
                <div className="custom-quill-editor">
                  <ReactQuill
                    theme="snow"
                    value={htmlContent || ''}
                    modules={editorModules}
                    onChange={handleEditorChange}
                    placeholder="Enter closing text"
                  />
                </div>
              </Form.Item>
            )}
          </Form.Item>
        </Col>
        <Col xl={6} span={24}>
          <div className="flex-row flex-justify-end flex-align-end h-100">
            <div className="flex-col gap-4">
              <Typography.Title level={3}>
                {profile?.firstName} {profile?.lastName}
              </Typography.Title>
              <Typography className="color-gray text-align-right">{profile?.phoneNumber}</Typography>
            </div>
          </div>
        </Col>
      </Row>
    </ReportSection>
  );
};

ClosingText.defaultProps = {
  provided: undefined,
};

export default ClosingText;
