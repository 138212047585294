import Icon from '@ant-design/icons';
import { Button, Form, Input, Modal, ModalFuncProps, Space, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { useContextNotifications } from '../../../../context/notifications';
import { JsonResult } from '../../../../types';
import { copyTextToClipboard } from '../../../../utils/text';
import { Link } from '../../../Common/Icon';
// import { login } from '../../../../utils/inputRules';

interface IShareModal extends ModalFuncProps {
  data?: JsonResult;
}

const ShareModal = ({ data, open, onCancel, afterClose }: IShareModal) => {
  const { openNotification } = useContextNotifications();
  const [form] = useForm();

  useEffect(() => {
    if (!open) return;

    form.resetFields();
  }, [open]);

  const cancle = () => {
    afterClose?.();
    onCancel?.();
  };

  return (
    <Modal footer={null} open={open} onCancel={cancle} maskClosable>
      <Space style={{ marginBottom: '10px' }} direction="vertical" size={16}>
        <Typography.Title level={2}>Share report</Typography.Title>
      </Space>

      <Form form={form} layout="vertical" initialValues={{ link: data?.link, reportId: data?.reportId }}>
        <div className="input-row-with-button">
          <Form.Item name="reportId" noStyle />
          <Form.Item name="link" label="Copy link to share">
            <Input size="large" placeholder="Report Link" disabled />
          </Form.Item>
          <Button
            type="primary"
            size="large"
            onClick={() =>
              copyTextToClipboard(data?.link).then(() => {
                openNotification?.({ message: 'The link is copied to the clipboard' });
                cancle();
              })
            }
            icon={<Icon component={Link} />}
          />
        </div>
      </Form>
    </Modal>
  );
};

ShareModal.defaultProps = {
  id: undefined,
  data: {},
};

export default ShareModal;
