import { Image, Space, Typography } from 'antd';
import clsx from 'clsx';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useContextReports } from '../../../../context/reports';

import styles from '../../index.module.less';

function SiderCommon(): JSX.Element {
  const location = useLocation();
  const sections = useRef<NodeListOf<HTMLDivElement>>();
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const { report, settings } = useContextReports();
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

  const handleScrollTo = (id: string) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    setIsMenuOpened(false);
  };

  useEffect(() => {
    if (!location.hash) return;

    const id = location.hash.replace('#', '');

    handleScrollTo(id);
  }, [location]);

  const handleScroll = (event: Event) => {
    const target = event.target as HTMLDivElement;

    const { offsetTop, scrollTop, offsetHeight } = target;
    let newActiveSection = null;

    sections.current?.forEach((section) => {
      const sectionOffsetTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;

      if (
        scrollTop + offsetTop <= sectionOffsetTop &&
        scrollTop + offsetTop + offsetHeight >= sectionOffsetTop + sectionHeight
      ) {
        newActiveSection = section.id;
      }
    });

    if (activeSection !== newActiveSection) {
      setActiveSection(newActiveSection);
    }
  };

  useEffect(() => {
    const scrollContent = document.getElementById('scroll-content');

    scrollContent?.addEventListener('scroll', handleScroll);

    return () => {
      scrollContent?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (sections.current || !document.querySelectorAll('[data-section]').length) return;
    sections.current = document.querySelectorAll('[data-section]');

    if (!location.hash) return;

    const id = location.hash.replace('#', '');

    handleScrollTo(id);
  }, [document.querySelectorAll('[data-section]')]);

  return (
    <div className={styles.siderContent}>
      <div className={clsx('flex-col w-100 d-m-flex d-lg-none', { [styles.opened]: isMenuOpened })}>
        <div className={styles.siderRow}>
         <div />
          <div
            className={clsx(styles.hamburger, { [styles.checked]: isMenuOpened })}
            onClick={() => setIsMenuOpened((prev) => !prev)}
          >
            <div className={styles.line} />
            <div className={styles.line} />
            <div className={styles.line} />
          </div>
        </div>
        <div className={styles.menuWrapper}>
          <div className={styles.menuContainer}>
            <div className={styles.menuContent}>
              <Space direction="vertical" size={16}>
                <div className="flex-col gap-4">
                  <Typography.Text className="color-dark-blue fs-1 fw-500">{report?.name}</Typography.Text>
                  <Typography.Text className="color-gray fs-075">
                    {report?.createdAt && moment(report.createdAt).format('ddd DD MMM, YYYY hh:mm a')}
                  </Typography.Text>
                </div>

                <Space direction="vertical" size={8}>
                  {settings?.reportView
                    .filter((item) => item.active)
                    .sort((a, b) => a.order - b.order)
                    .map((item) => (
                      <Link
                        key={item.id}
                        to={`#${item.name}`}
                        className={clsx({ 'color-gray': activeSection !== item.name })}
                      >
                        {item.text}
                      </Link>
                    ))}
                </Space>
              </Space>
            </div>
          </div>
        </div>
      </div>
      <div className={clsx(styles.sider, 'd-lg-grid', 'd-m-none')}>
        <div className={styles.logo}>
          <Image
            preview={false}
            src={report?.User?.logo || '/logo.png'}
            alt="Company Logo"
            height={75}
            style={{ objectFit: 'contain' }}
          />
        </div>
        <Space direction="vertical" size={16}>
          <div className="flex-col gap-4">
            <Typography.Text className="color-dark-blue fs-1 fw-500">{report?.name}</Typography.Text>
            <Typography.Text className="color-gray fs-075">
              {report?.createdAt && moment(report.createdAt).format('ddd DD MMM, YYYY hh:mm a')}
            </Typography.Text>
          </div>

          <Space direction="vertical" size={8}>
            {settings?.reportView
              .filter((item) => item.active)
              .sort((a, b) => a.order - b.order)
              .map((item) => (
                <Link
                  key={item.id}
                  to={`#${item.name}`}
                  className={clsx({ 'color-gray': activeSection !== item.name })}
                >
                  {item.text}
                </Link>
              ))}
          </Space>
        </Space>
      </div>
    </div>
  );
}

export default SiderCommon;
