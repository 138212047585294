import qs from 'querystring';
import { Button, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCategories } from '../../../hooks/categories';
import { useScripts } from '../../../hooks/scripts';
import Loading from '../../Common/Loading';
import Category from './Category';
import List from './List';
import { useContextUsers } from '../../../context/users';

const ContentScripts = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const categories = useCategories();
  const scripts = useScripts();
  const { profile, getProfile } = useContextUsers();
  const [activeCategory, setActiveCategory] = useState<string>();

  useEffect(() => {
    if (!getProfile) return;

    getProfile();
  }, []);

  useEffect(() => {
    categories.fetch();
  }, []);

  useEffect(() => {
    scripts.fetch({ id: activeCategory });
  }, [activeCategory]);

  const handleCategoryChange = (category: number | undefined) => {
    const search = category ? { category } : undefined;

    navigate({ pathname: location.pathname, search: qs.stringify(search) });
  };

  const handleCreateNewScript = () => {
    navigate(`${location.pathname}/create`);
  };

  useEffect(() => {
    const { category } = qs.parse(location.search.replace('?', ''));

    if (category === activeCategory) return;

    setActiveCategory((category as string) || undefined);
  }, [location]);

  return (
    <div className="flex-col gap-24">
      <Loading visible={categories.loading || scripts.loading} absolute />
      <Typography.Title>Content Scripts</Typography.Title>
      <div className="flex-row gap-12 flex-align-center flex-justify-space-between">
        <div className="flex-row gap-12">
          <Category label="All scripts" isActive={!activeCategory} onClick={() => handleCategoryChange(undefined)} />
          {categories.data?.data.map((category) => (
            <Category
              key={category.id}
              label={category.name}
              isActive={activeCategory === category.id.toString()}
              onClick={() => handleCategoryChange(category.id)}
            />
          ))}
        </div>
        {!!profile && profile.type === 'admin' && (
          <Button size="large" type="primary" onClick={handleCreateNewScript}>
            Add New
          </Button>
        )}
      </div>
      {scripts.data?.data && <List scripts={scripts.data.data} />}
    </div>
  );
};

export default ContentScripts;
