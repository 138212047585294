import { Form } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import debounce from "just-debounce-it";
import { useContextReports } from '../../../../../context/reports';
import { useContextUsers } from '../../../../../context/users';
import ReportSection from '../Section';

import 'react-quill/dist/quill.snow.css';
import './style.less';

// const { Panel } = Collapse;

export const editorModules = {
  toolbar: [
    [{ size: [] }],
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ align: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
    ['link', 'image', 'video'],
  ],
};

export const editorModuleReadOnly = {
  toolbar: false,
};

interface IWelcomeMessage {
  isActive: boolean;
}

const WelcomeText = ({ isActive }: IWelcomeMessage) => {
  const name = 'welcome_message'
  const form = useFormInstance();
  const { profile } = useContextUsers();
  const { report, isBorrower, handleReportUpdate } = useContextReports();
  const [isDisabled, setIsDesabled] = useState<boolean>(true);
  const [htmlContent, setHtmlContent] = useState<string | null | undefined>();

  useEffect(() => {
    if (!profile && !report) return;
    setHtmlContent(report?.welcome_message);
    setIsDesabled(false);

    if (report?.name) {
      setIsDesabled(false);
    }
  }, [profile, report]);

  const debouncedHandleReportUpdate = debounce(() => {
    handleReportUpdate?.();
  }, 500);

  const handleEditorChange = (content: string) => {
    setHtmlContent(content);
    form.setFieldValue(['report', 'welcome_message'], content);
    debouncedHandleReportUpdate();
  };

  if ((isBorrower && htmlContent === '<p><br></p>') || (!isActive && isBorrower)) {
    return null;
  }

  return (
    <ReportSection
      show={isBorrower && isActive}
      name={name}
      title={!isBorrower ? 'Welcome Message' : ''}>
      <div id="welcomeMessage" className="flex-col gap-8">
        {isBorrower ? (
          <div className="custom-quill-editor-readonly">
            <ReactQuill theme="snow" value={htmlContent || ''} modules={editorModuleReadOnly} readOnly />
          </div>
        ) : (
          <Form.Item name={['report', 'welcome_message']} label={!isBorrower ? 'Enter Text' : undefined} noStyle>
            <div className="custom-quill-editor">
              <ReactQuill
                theme="snow"
                value={htmlContent || ''}
                modules={editorModules}
                readOnly={isDisabled}
                onChange={handleEditorChange}
                placeholder="Enter script text"
              />
            </div>
          </Form.Item>
        )}
      </div>
    </ReportSection>
  );
};

WelcomeText.defaultProps = {
  provided: undefined,
};

export default WelcomeText;
