import Layout from '../../components/Layout/Simple';
import Content from '../../components/Pages/Referral';
import { displayName } from '../../config';

const Referral = (): JSX.Element => {
  document.title = `${displayName}: Referral`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default Referral;
