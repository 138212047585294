import Layout from '../../../components/Layout/Simple';
import Content from '../../../components/Pages/Reports/UploadQuote';
import { displayName } from '../../../config';

const UploadQuote = (): JSX.Element => {
  document.title = `${displayName}: Upload Quote`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default UploadQuote;
