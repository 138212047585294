import Layout from '../../../components/Layout/Auth';
import Wrapper from '../../../components/Pages/Auth';
import Content from '../../../components/Pages/Auth/ForgotPassword';
import { displayName } from '../../../config';

const ForgotPassword = (): JSX.Element => {
  document.title = `${displayName}: Forgot Password`;

  return (
    <Layout>
      <Wrapper title="Forgot Password" text="You will receive an email with link to reset your password.">
        <Content />
      </Wrapper>
    </Layout>
  );
};

export default ForgotPassword;
