import { ILoanAdvancedValue, ILoanValue } from '../types/reports';
import { commafy } from './text';

export const prepareLoanDataFromForm = (columns: ILoanValue[], fields?: string[]) =>
  columns?.map((column) => {
    const loan: ILoanValue = {};

    Object.entries(column).forEach(([key, item]) => {
      if (fields?.length && !fields.some((field) => field === key)) return;

      const advancedItem = item as ILoanAdvancedValue;

      if (key === 'color') {
        loan[key] = item;

        return;
      }

      if (item && Object.keys(item).includes('value') && !advancedItem.value) {
        loan[key] = null;

        return;
      }

      if (typeof item === 'string' || typeof item === 'number') {
        loan[key] = item.toString();

        return;
      }

      if (advancedItem?.value) {
        loan[key] = advancedItem?.value ? `${advancedItem.value}`.replace(/,/g, '') : null;

        return;
      }

      loan[key] = item || undefined;
    });

    return loan;
  });

export const calculateMonthlyPayment = (interestRate: string, loanAmount: string, loanPeriodInYears = 30): string => {
  if (!loanAmount || !interestRate) return '0';

  const R = parseFloat(loanAmount);
  const r = parseFloat(interestRate) / 100 / 12;
  const n = loanPeriodInYears * 12;

  const x = (1 + r) ** n;
  const M = Math.round(R * ((r * x) / (x - 1)));

  return Number.isNaN(M) ? '0' : commafy(M);
};