import { FetchResponse, FetchSuccess } from '../types';
import {
  DefaultFetchError,
  FetchDelete,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchDelete,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';

export interface INews {
  id: number;
  header: string;
  category: string;
  body: string;
  author: string;
  origin: string;
  imgUrl: string;
  originalContent: string;
  pubDate: string;
  link: string;
  prompts: string;
}

// New interface for responses with pagination
export interface INewsResponse {
  data: INews[];
  totalRecords: number;
}

interface INewsUpdate {
  header?: string;
  category?: string;
  body?: string;
}

interface INewsRegenerate {
  prompts: string[] | null;
}

export interface INewsParams {
  id?: string;
  page?: number;
  pageSize?: number;
  searchQuery?: string;
}

export const useNews = (): FetchGet<FetchResponse<INewsResponse>, INewsParams> =>
  useFetchGet('news', { autoStart: false, startStateLoading: true, multiple: 'news' });

export const useNewsId = (): FetchGetId<FetchResponse<INews>> =>
  useFetchGetId('news', '', { autoStart: false, startStateLoading: false, multiple: 'script' });

export const useUpdateNews = (): FetchUpdate<FetchSuccess, DefaultFetchError, INewsUpdate> =>
  useFetchUpdate('news', '');

export const useNewsWeekly = (): FetchGet<FetchResponse<INewsResponse>, INewsParams> =>
  useFetchGet('news/getWeeklyTable', { autoStart: false, startStateLoading: true, multiple: 'news' });

export const useNewsRegenerate = (): FetchUpdate<FetchSuccess, DefaultFetchError, INewsRegenerate> =>
  useFetchUpdate('news/regenerate', '');

export const useDeleteNews = (): FetchDelete<FetchSuccess> => useFetchDelete('news', '');
