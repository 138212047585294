import { Col, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useCurrentMarketRates } from '../../../hooks/reports';
import MarketRatesChart from '../../Common/MarketRatesChart';

const MarketRates = () => {
  const currentMarketRates = useCurrentMarketRates();
  const currentTreasury = useCurrentMarketRates();
  let interval: ReturnType<typeof setTimeout>;

  const getChartsData = () => {
    currentMarketRates.fetch({ years: 30 });

    currentTreasury.fetch({ years: 10 });
  };

  useEffect(() => {
    getChartsData();
    interval = setInterval(() => {
      getChartsData();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <Typography.Title level={1}>Markets</Typography.Title>
      <Row gutter={[20, 20]} justify="space-between">
        <Col style={{ minWidth: "780px" }} xs={24} md={24} lg={24} xl={12} xxl={12}>
          <MarketRatesChart
            title="UMBS 30 Year - 5.0% Coupon"
            data={currentMarketRates.data?.data}
            loading={currentMarketRates.loading}
            height={300}
            reversed={false}
          />
        </Col>
        <Col style={{ minWidth: "780px" }} xs={24} md={24} lg={24} xl={12} xxl={12}>
          <MarketRatesChart
            title="US 10 Year Treasury"
            data={currentTreasury.data?.data}
            loading={currentTreasury.loading}
            reversed
            height={300}
          />
        </Col>
      </Row>
    </div>
  );
};

export default MarketRates;
