import { FetchResponse } from '../types';
import { FetchGet, useFetchGet } from './fetch';

export enum LoginRecordActionType {
  Login = "LOGIN",
  Register = "REGISTER"
}

type ApiResponse = {
  tableData: TableDataItem[];
  page: number;
  limit: number;
  totalPages: number;
  totalUsers: number | undefined;
};

type IDemoCount = {
  demoReportsCount: number | null;
};

export type TableDataItem = {
  id: string;
  email: string;
  name: string | null;
  phoneNumber: string | null;
  nmlsId: string;
  isActive: boolean;
  reportNumber: number;
  plan: string;
  records: LoginRecrod[];
};

export interface ITableParams {
  page?: number;
  pageSize?: number;
  sortBy?: string | null;
  sortDirection?: string | null;
  searchText: string | null;
  searchedColumn: string | null;
}

export type LoginRecrod = {
  loginTime: Date;
  actionType: LoginRecordActionType;
}

export const useGetTableData = (): FetchGet<FetchResponse<ApiResponse>, ITableParams> =>
  useFetchGet('admin', { autoStart: false, startStateLoading: true, multiple: 'admin' });

export const useGetDemoCount = (): FetchGet<FetchResponse<IDemoCount>> =>
  useFetchGet('admin/demoCount');