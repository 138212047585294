import Icon from '@ant-design/icons';
import { Button, Space, Switch, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Stripe from 'stripe';
import { useContextNotifications } from '../../../context/notifications';
import { useContextUsers } from '../../../context/users';
import { useCustomerPaymentMethods, usePlans, usePaymentCreate, usePaymentUpgrade } from '../../../hooks/stripe';
import ContentCard from '../../Common/ContentCard';
import { Back } from '../../Common/Icon';
import Loading from '../../Common/Loading';
import PlansDetails from '../../Common/PlansDetails';

const Plans = () => {
  const navigate = useNavigate();

  const { profile } = useContextUsers();
  const { openNotification } = useContextNotifications();

  const getPlans = usePlans();
  const paymentCreate = usePaymentCreate();
  const upgradePayment = usePaymentUpgrade();
  const [plans, setPlans] = useState<Stripe.Plan[]>();
  const [isYearly, setIsYearly] = useState<boolean>(false);
  const [selectedPlanId, setSelectedPlanId] = useState<string>();
  const customerPaymentMethods = useCustomerPaymentMethods();
  const [isToggled, setIsToggled] = useState<boolean>(false);

  useEffect(() => {
    customerPaymentMethods.fetch();
  }, []);

  useEffect(() => {
    getPlans.fetch({ type: isYearly ? 'year' : 'month' });
  }, [isYearly]);

  useEffect(() => {
    if (!getPlans.data?.data) return;
    const newPlans = Object.values(getPlans.data.data).sort((a, b) => (a.amount || 0) - (b.amount || 0));

    setPlans(newPlans);

    const userPlan = newPlans.find((plan) => plan.id === profile?.planId);

    if (!isToggled) {
      if (userPlan?.interval === 'year' || !userPlan) {
        setIsYearly(true);
      } else {
        setIsYearly(false);
      }
    }
  }, [getPlans.data]);

  useEffect(() => {
    if (!profile?.planId) return;

    setSelectedPlanId(profile.planId);
  }, [profile]);

  const handleBack = () => {
    navigate({ pathname: '/profile', search: 'tab=payment' });
  };

  const handleChange = (id: string) => {
    if (!id) return;

    setSelectedPlanId(id);

    const defaultPaymentMethod =
      customerPaymentMethods.data?.data.data.find((item) => item.isDefault) ||
      customerPaymentMethods.data?.data.data[0];

    if (!defaultPaymentMethod) return;
    const selectedPlanIndex = plans?.findIndex((plan) => plan.id === selectedPlanId);
    const newPlanIndex = plans?.findIndex((plan) => plan.id === id);

    if (selectedPlanIndex && newPlanIndex) {
      upgradePayment
        .fetch({
          planId: id,
          type: selectedPlanIndex > newPlanIndex ? 'Downgrade' : 'Upgrade',
        })
        .then(() => {
          handleBack();
          openNotification?.({ message: 'Plan was changed successfully' });
        });
    }
    setIsToggled(true);
  };

  const toggleSwitch = () => {
    setIsYearly(!isYearly);
    setIsToggled(true);
  };

  return (
    <section>
      <Loading visible={paymentCreate.loading} absolute />
      <Space direction="vertical" size={32}>
        <div className="flex-row flex-align-center flex-justify-space-between gap-12">
          <div className="flex-row flex-align-center gap-4">
            <Button type="ghost" icon={<Icon component={Back} style={{ fontSize: '1.25em' }} />} onClick={handleBack} />
            <Typography.Title>Plan</Typography.Title>
          </div>
          <div className="flex-row flex-align-center gap-12">
            <Switch onChange={toggleSwitch} checked={isYearly} defaultChecked={isYearly} />
            <Typography.Text className="fw-600 color-gray">Yearly</Typography.Text>
            <div
              style={{
                padding: '2px 10px',
                color: 'var(--color-blue)',
                border: '1px solid var(--color-blue)',
                borderRadius: 4,
              }}
            >
              20% OFF
            </div>
          </div>
        </div>

        <Space direction="vertical" size={12}>
          <Typography.Title level={3}>All Plans</Typography.Title>
          <ContentCard loading={getPlans.loading}>
            {plans && (
              <PlansDetails
                plans={plans}
                selectedPlan={selectedPlanId}
                handleChange={handleChange}
                loading={paymentCreate.loading}
              />
            )}
          </ContentCard>
        </Space>
      </Space>
    </section>
  );
};

export default Plans;
