import React, { useEffect, useState, useRef } from 'react';
import Icon from '@ant-design/icons';
import moment from 'moment';
import {
  Table,
  TableColumnsType,
  InputRef,
  TablePaginationConfig,
  Space,
  Table as AntdTable,
  Button,
  Popover,
} from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import { useContextNotifications } from '../../../context/notifications';
import { useGetTableData, TableDataItem } from '../../../hooks/admin';
import { useUserDelete } from '../../../hooks/users';
import { GetColumnSearchProps } from './search';
import styles from '../index.module.less';
import 'antd/dist/antd.css';
import { Delete, Dots, Viewers } from '../../../components/Common/Icon';

interface PaginationState {
  current: number;
  pageSize: number;
  total: number;
  sortBy: string | null;
  sortDirection: string | null;
}

const UserTable = () => {
  const [update, handleUpdate] = useState<boolean>(false);
  const [data, setData] = useState<TableDataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const [pagination, setPagination] = useState<PaginationState>({
    current: 1,
    pageSize: 10,
    total: 0,
    sortBy: null,
    sortDirection: null,
  });
  const deleteUser = useUserDelete();
  const { openNotification, openConfirm, openInfo } = useContextNotifications();
  const getData = useGetTableData();
  const searchInput = useRef<InputRef>(null);
  const [popoverVisible, setPopoverVisible] = useState<boolean>(true);

  const togglePopover = () => {
    setPopoverVisible((prevVisible) => !prevVisible);
  };

  const handleDeleteUser = (userId: string, handleClose: () => void) => {
    setLoading(true);
    if (!userId) return;
    deleteUser
      .fetch(userId)
      .then(() => {
        openNotification?.({ message: 'User deleted successfully' });
        handleUpdate(!update);
      })
      .catch((error) => {
        setLoading(false);
        openNotification?.({ message: `Error while deleting user: ${error}`, type: 'error' });
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  const fetchData = () => {
    setLoading(true);
    getData
      .fetch({
        page: pagination.current,
        pageSize: pagination.pageSize,
        sortBy: pagination.sortBy,
        sortDirection: pagination.sortDirection,
        searchText,
        searchedColumn,
      })
      .then((response) => {
        setData(response?.data.tableData || []);
        setLoading(false);
        setPagination((prev) => ({
          ...prev,
          total: response?.data.totalUsers || 0,
        }));
      })
      .catch((error) => {
        setLoading(false);
        openNotification?.({
          message: `Error while Getting Data: ${error}`,
          type: 'error',
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, [
    pagination.current,
    searchedColumn,
    searchText,
    pagination.pageSize,
    pagination.sortBy,
    pagination.sortDirection,
    update,
  ]);

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any
  ) => {
    const sortField = sorter.field;
    const sortOrder = sorter.order === 'ascend' ? 'ASC' : 'DESC';

    setPagination({
      ...pagination,
      current: newPagination.current || 1,
      pageSize: newPagination.pageSize || 10,
      sortBy: sortField,
      sortDirection: sortOrder,
    });
  };

  const tableRowActions = (row: TableDataItem) => (
    <Space direction="vertical" className={styles.popoverContent}>
      <Button
        type="ghost"
        icon={<Icon component={Viewers} />}
        block
        onClick={() => {
          togglePopover();
          openInfo?.({
            title: 'Records for user',
            content:

              <AntdTable
                size="small"
                columns={[
                  {
                    align: 'center',
                    title: 'Login Time',
                    dataIndex: 'loginTime',
                    key: 'loginTime',
                    render: dom => moment(dom).format('YYYY-MM-DD HH:mm:ss') || 'N/A',
                  },
                  {
                    align: 'center',
                    title: 'Action Type',
                    dataIndex: 'actionType',
                    key: 'actionType',
                    render: dom => dom || 'N/A',
                  },
                ]}
                scroll={{ y: 250 }}
                dataSource={row.records}
                rowKey="id"
                pagination={false}
              />
            ,
            okText: 'Close',
            onOk: () => togglePopover(),
          });
        }}
      >
        Viewers
      </Button>
      <Button
        type="ghost"
        icon={<Icon component={Delete} />}
        block
        onClick={() => {
          togglePopover();
          openConfirm?.({
            title: 'Delete Report ',
            content: `Are you sure you want to delete report ${row.name ?? ''}?`,
            okText: 'Delete',
            okType: 'danger',
            maskClosable: true,
            onOk: (handleClose) => handleDeleteUser(row.id, handleClose),
            onCancel: () => togglePopover(),
            afterClose: () => togglePopover(),
          });
        }}
      >
        Delete
      </Button>
    </Space>
  );

  const COLUMN: TableColumnsType<TableDataItem> = [
    {
      title: 'User ID',
      dataIndex: 'id',
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ...GetColumnSearchProps<TableDataItem>({
        dataIndex: 'email',
        indexName: 'Email',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
      }),
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Date created',
      dataIndex: 'createdAt',
      sorter: true,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'NMLS ID',
      dataIndex: 'nmlsId',
      ...GetColumnSearchProps<TableDataItem>({
        dataIndex: 'nmlsId',
        indexName: 'NMLS ID',
        searchText,
        searchedColumn,
        setSearchText,
        setSearchedColumn,
        searchInput,
      }),
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      sorter: true,
      render: (isActive: boolean) => (isActive ? 'Yes' : 'No'),
    },
    {
      title: 'Reports Created',
      dataIndex: 'reportNumber',
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
    },
    {
      title: 'Actions',
      sorter: false,
      render: (_, row) => (
        <div className={`table-hover-actions ${styles.actionButton}`}>
          {popoverVisible && (
            <Popover content={() => tableRowActions(row)} placement="bottomRight" trigger="click" showArrow={false}>
              <Button size="large" type="ghost" icon={<Icon component={Dots} />} />
            </Popover>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={COLUMN}
      rowKey={(record) => record.id}
      dataSource={data}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
    />
  );
};

export default UserTable;
