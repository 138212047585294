import Icon from '@ant-design/icons';
import { SortOrder } from 'antd/es/table/interface';
import moment from 'moment-timezone';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { Button, Space, Typography } from 'antd';
import React, { useRef } from 'react';
import Stripe from 'stripe';
import { useTableInvoiceRow } from '../../../../../hooks/stripe';
import { getSorterParams, queryFilterParams } from '../../../../../utils';
import { Download } from '../../../../Common/Icon';

import Table from '../../../../Common/Table';

function InvoicesTable(): JSX.Element {
  const invoicesGet = useTableInvoiceRow();
  const actionRef = useRef<ActionType>();

  const tableRequest = (
    {
      current,
      pageSize,
      ...args
    }: Record<string, string> & {
      pageSize?: number | undefined;
      current?: number | undefined;
      keyword?: string | undefined;
    },
    sorter: Record<string, SortOrder>
  ): Promise<Partial<RequestData<Stripe.Invoice>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      limit: pageSize ? `${pageSize}` : '100',
      ...args,
      ...getSorterParams(sorter),
    });

    return invoicesGet.fetch(newParams).then((data) => {
      if (data) {
        const { invoices, total } = data;

        return { data: invoices || [], success: true, total };
      }

      return { data: [], success: false, total: 0 };
    });
  };

  const columns: ProColumns<Stripe.Invoice>[] = [
    {
      title: 'DATE',
      dataIndex: 'created',
      sorter: false,
      renderText: (created) => moment.unix(created).format('ddd DD MMM, YYYY hh:mm a'),
    },
    {
      title: 'NAME',
      dataIndex: 'number',
      sorter: false,
    },
    {
      title: 'PRICE',
      dataIndex: 'total',
      sorter: false,
      renderText: (total) => `$${(total / 100).toFixed(2)}`,
    },
    {
      title: 'ACTION',
      sorter: false,
      align: 'right',
      render: (node, invoice) => (
        <Button
          style={{ marginLeft: 'auto', display: 'block', textAlign: 'right' }}
          type="ghost"
          className="color-gray"
          icon={<Icon component={Download} />}
          href={invoice.invoice_pdf || undefined}
          download
        >
          DOWNLOAD INVOICE
        </Button>
      ),
    },
  ];

  return (
    <Space direction="vertical" size={12}>
      <Typography.Title level={2}>Billing History</Typography.Title>
      <Table<Stripe.Invoice>
        search={false}
        columns={columns}
        request={tableRequest}
        actionRef={actionRef}
        showSorterTooltip={false}
        toolBarRender={false}
        pagination={false}
      />
    </Space>
  );
}

export default InvoicesTable;
