import { Rule } from 'antd/es/form';

export const email: Rule[] = [{ type: 'email', message: 'Please enter a valid email' }];
export const number: Rule[] = [{ pattern: /^[0-9]+$/, message: 'The field should contain only numbers' }];
export const required = (name: string): Rule[] => [{ required: true, message: `Please enter a valid ${name}` }];
export const minOneSymbols: Rule[] = [{ min: 1, message: 'Please enter at least 1 characters' }];
export const minThreeSymbols: Rule[] = [{ min: 3, message: 'Please enter at least 3 characters' }];
export const minSixSymbols: Rule[] = [{ min: 6, message: 'Please enter at least 6 characters' }];
export const minEightSymbols: Rule[] = [{ min: 8, message: 'Please enter at least 8 characters' }];
export const maxEightSymbols: Rule[] = [{ max: 8, message: 'Please enter less than 8 characters' }];
export const role: Rule[] = [...required('role')];

export const isRequeredNMLS: Rule[] = [{ min: 0, message: 'Please enter a valid NMLS ID' }];

export const noWhiteSpaces: Rule[] = [{ pattern: /^\S+/g, message: 'This field cannot contain white spaces' }];

export const login: Rule[] = [...minThreeSymbols, ...required('email'), ...email];
export const password: Rule[] = [...required('password'), ...minEightSymbols, ...noWhiteSpaces];
export const nmls: Rule[] = [
  { required: true, message: 'Please enter a valid NMLS ID' },
  ...isRequeredNMLS,
  ...maxEightSymbols,
];
