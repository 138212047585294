import Layout from '../../components/Layout/Simple';
import Content from '../../components/Pages/Errors/NotAuthorized';
import { displayName } from '../../config';

const NotAuthorized = (): JSX.Element => {
  document.title = `${displayName}: Not Authorized`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default NotAuthorized;
